import ApiService from "@/core/services/api.service";

const _ = require('lodash');
export const CREATE_BRANCH = "create_branch";
export const UPDATE_BRANCH = "update_branch";
export const GET_BRANCHES = "get_branches";
export const GET_BRANCHES_LIST = "branches/list";
export const GET_LIST_BRANCHES = "get_list_branches";
export const GET_BRANCH_BY_ID = "get_by_id";
export const DELETE_BRANCH = "delete_branch";
export const IMPORT_BRANCH_DATA = "import-branch-data";
export const GET_BRANCH_BY_LIST_CENTER_ID = "get-branch-by-list-center-id";
export const GET_ALL_BRANCHES = "get-all-branch";
export const GET_OFFICE = "get-all-office";
export const GET_AREA = "get-all-area";

// office

export const GET_KPI_OFFICE = "kpi-office";
export const DELETE_KPI_OFFICE = "delete-kpi-office";
export const EXPORT_FILE_DEFAULT_KPI_OFFICE = "kpi-office/export-file-default-kpi-office";
export const IMPORT_EXCEL_KPI_OFFICE = "kpi-office/import-excel-kpi-office";

const state = {};

const getters = {};

const actions = {
    // Get provinces
    [CREATE_BRANCH](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post('branches', payload).then(({data}) => {

                resolve(data);
            }).catch(({response}) => {
                reject(response)
            });
        })
    },
    [UPDATE_BRANCH](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.update('branches', payload.id, payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response)
            });
        })
    },
    [GET_BRANCHES](context, payload) {
        return new Promise(resolve => {
            ApiService.query('branches', payload).then(({data}) => {
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },
    [GET_BRANCHES_LIST](context, payload) {
        return new Promise(resolve => {
            ApiService.query(GET_BRANCHES_LIST, payload).then(({data}) => {
                resolve(data);
            }).catch((response) => {
                reject(response)
            });
        })
    },
    [GET_OFFICE](context, payload) {
        return new Promise(resolve => {
            ApiService.query('get-all-office', payload).then(({data}) => {
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },
    [GET_AREA](context, payload) {
        console.log(payload,"payload")
        return new Promise(resolve => {
            ApiService.query('get-all-area', payload).then(({data}) => {
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },
    [GET_LIST_BRANCHES](context, payload) {
        return new Promise(resolve => {
            ApiService.query('branches/get-list', payload).then(({data}) => {
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },
    [GET_ALL_BRANCHES](context, payload) {
        return new Promise(resolve => {
            ApiService.query(GET_ALL_BRANCHES, payload).then(({data}) => {
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },
    [GET_BRANCH_BY_ID](context, id) {
        return new Promise((resolve) => {
            ApiService.get("branches", id)
                .then(({data}) => {
                    resolve(data);
                })
                .catch((response) => {
                    console.log(response)
                });
        });
    },
    [DELETE_BRANCH](context, id) {
        return new Promise((resolve) => {
            ApiService.delete("branches", id)
                .then(({data}) => {
                    resolve(data);
                })
                .catch((response) => {
                    console.log(response)
                });
        });
    },
    [IMPORT_BRANCH_DATA](context, payload) {
        return new Promise((resolve) => {
            ApiService.post("import-branch-data", payload)
                .then(({data}) => {
                    resolve(data);
                })
                .catch((response) => {
                    console.log(response)
                });
        });
    },
    [GET_BRANCH_BY_LIST_CENTER_ID](context, payload) {
        return new Promise((resolve) => {
            ApiService.query(GET_BRANCH_BY_LIST_CENTER_ID, payload)
                .then(({data}) => {
                    resolve(data);
                })
                .catch((response) => {
                    // console.log(response)
                });
        });
    },

    // office
    [GET_KPI_OFFICE](context, payload) {
        return new Promise((resolve) => {
            ApiService.query(GET_KPI_OFFICE, payload)
                .then(({data}) => {
                    resolve(data);
                })
                .catch((response) => {
                    // console.log(response)
                });
        });
    },

    [DELETE_KPI_OFFICE](context, id) {
        return new Promise((resolve) => {
            ApiService.delete(GET_KPI_OFFICE, id)
                .then(({data}) => {
                    resolve(data);
                })
                .catch((response) => {
                    // console.log(response)
                });
        });
    },

    [EXPORT_FILE_DEFAULT_KPI_OFFICE](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query(EXPORT_FILE_DEFAULT_KPI_OFFICE, payload).then(({ data }) => {
                let filename = "File-mau-import-kpi-van-phong.xlsx";
                let anchor = document.createElement("a");
                anchor.setAttribute("download", filename);
                anchor.setAttribute("href", data.data);
                document.body.appendChild(anchor);
                anchor.click();
                document.body.removeChild(anchor);
                resolve(data);
            }).catch(({ response }) => {
                reject(response);
            });
        });
    },

    [IMPORT_EXCEL_KPI_OFFICE](context, payload) {
        return new Promise((resolve) => {
            ApiService.post(IMPORT_EXCEL_KPI_OFFICE, payload)
                .then(({data}) => {
                    resolve(data);
                })
                .catch((response) => {
                    console.log(response)
                });
        });
    },
};
const mutations = {};
export default {
    state,
    actions,
    mutations,
    getters
};

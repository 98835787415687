export const PERMISSION = {
    QUAN_LY_NHAN_SU :'quan-ly-nhan-su',
    KHOI_CO_SO :'khoi-co-so',
    QUAN_LY_NHAN_SU_KHOI_CO_SO:'quan-ly-nhan-su-khoi-co-so',
    DANH_SACH_NHAN_SU:'danh-sach-nhan-su',
    XEM_DANH_SACH_NHAN_SU_CO_SO:'xem-danh-sach-nhan-su-co-so',
    XEM_CHI_TIET_NHAN_SU_CO_SO:'xem-chi-tiet-ds-nhan-su-co-so',
    SUA_NHAN_SU_CO_SO:'sua-nhan-su-co-so',
    XOA_NHAN_SU_CO_SO:'xoa-nhan-su-co-so',
    THEM_NHAN_SU_CO_SO:'them-moi-nhan-su-co-so',
    EXPORT_NHAN_SU_CO_SO:'export-nhan-su-co-so',
    DIEU_CHUYEN_NHAN_SU:'dieu-chuyen-nhan-su',
    SALE_LEN_CAP:'sale-len-cap',
    SALE_XUONG_CAP:'sale-xuong-cap',
    DANH_SACH_DONG_TIEN:'danh-sach-dong-tien',
    XEM_DANH_SACH_DONG_TIEN:'xem-danh-sach-dong-tien',
    SUA_THONG_TIN_DONG_TIEN:'sua-thong-tin-dong-tien',
    EXPORT_DANH_SACH_DONG_TIEN:'export-danh-sach-dong-tien',
    PHIEU_LUONG:'phieu-luong',
    BAO_CAO:'bao-cao',
    TRU_SO_TONG:'tru-so-tong',
    WEBSITE_TUYEN_DUNG:'website-tuyen-dung',
    QUAN_LY_KINH_DOANH:'quan-ly-kinh-doanh',
    QUAN_LY_KENH_CTV:'quan-ly-kenh-ctv',
    BAO_CAO_KENH_CTV:'bao-cao-kenh-ctv',
    QUAN_LY_KENH_WEBSITE:'quan-ly-kenh-website',
    QUAN_LY_LEAD:'quan-ly-lead',
    QUAN_LY_GIAO_DIEN:'quan-ly-giao-dien',
    DANH_SACH_TIN_TUC:'danh-sach-tin-tuc',
    DANH_SACH_SU_KIEN:'danh-sach-su-kien',
    DANH_SACH_PAGE:'danh-sach-page',
    QUAN_LY_TAI_CHINH:'quan-ly-tai-chinh',
    QUAN_LY_TAI_CHINH_2:'quan-ly-tai-chinh-2',
    // TAI_CHINH_THEO_KHOA_HOC: 'tai-chinh-theo-khoa-hoc',
    KHOA_HOC_DANG_KY: 'khoa-hoc-dang-ky',
    NAP_RUT_TANG_QUA:'nap-rut-tang-qua',
    EDUTALK_THU_TIEN:'edutalk-thu-tien',
    HOP_DONG_UNG:'hop-dong-ung',
    NAP_RUT_197:'nap-rut-197',
    XU_LY_SO_DU_TAI_KHOAN:'xu-ly-so-du-tai-khoan',
    BAO_CAO_TAI_CHINH:'bao-cao-tai-chinh',
    KHACH_HANG_DONG_TIEN:'khach-hang-dong-tien',
    DS_KH_DONG_TIEN:'xem-danh-sach-kh-dong-tien',
    SUA_NGAY:'sua-ngay',
    SUA_HE_SO:'sua-he-so',
    HUY:'huy',
    EXPORT_KHACH_HANG_DONG_TIEN:'export-kh-dong-tien',
    QUYET_TOAN_TRUNG_TAM:'quyet-toan-voi-trung-tam',
    CONG_NO_KHACH_HANG:'cong-no-khach-hang',
    QUAN_LY_SAN_XUAT:'quan-ly-san-xuat',
    QUAN_LY_DON_VI_SX:'quan-ly-don-vi-sx',
    QUAN_LY_DAY_HOC:'quan-ly-day-hoc',
    SUA_LOI_DAY_HOC:'sua-loi-day-hoc',
    TEST_DAU_VAO:'test-dau-vao',
    QUAN_LY_TESTER:'quan-ly-tester',
    SU_KIEN:'su-kien',
    WORKSHOP_CLUB:'workshop-club',
    DUYET_NGHI_BUOI_HOC:'duyet-buoi-nghi-hoc',
    QUAN_LY_HE_THONG_THONG_TIN:'quan-ly-he-thong-thong-tin',
    CRM:'crm',
    KHACH_HANG:'khach-hang',
    DS_KHACH_HANG:'danh-sach-khach-hang',
    EXPORT_DSKH:'export-danh-sach-khach-hang',
    XEM_DS_KH:'xem-danh-sach-kh',
    XEM_QLKH:'quan-ly-khach-hang',
    CHI_TIET_DSKH:'xem-chi-tiet-dskh',
    SUA_KHACH_HANG:'sua-khach-hang',
    XEM_LICH_SU:'xem-lich-su',
    DS_HD:'danh-sach-hop-dong',
    DS_HD_2:'danh-sach-hop-dong-2',
    XEM_DS_HD:'xem-danh-sach-hd',
    CHI_TIET_HD:'xem-chi-tiet-dshd',
    SUA_HD:'sua-hd',
    XOA_HD:'xoa-hd',
    TAO_HD:'tao-hop-dong',
    QUAN_LY_TAI_KHOAN:'quan-ly-tai-khoan',
    EXPORT_DANH_SACH_HOP_DONG:'export-danh-sach-hop-dong',
    HUY_TIEU_KHOA_HOC: 'huy-tieu-khoa-hoc',
    QUAN_LY_DON_VI_HANH_CHINH:'quan-ly-don-vi-hanh-chinh',
    QUAN_LY_HE_SO_KINH_DOANH:'quan-ly-he-so-kinh-doanh',
    BANG_BAO_LOI: 'bang-bao-loi',
    CHAM_SOC_HOC_VIEN: 'cham-soc-hoc-vien',
    XEM_DANH_SACH_TICKET: 'xem-danh-sach-ticket',
    XEM_CHI_TIET_TICKET: 'xem-chi-tiet-ticket',
    XU_LY_TICKET: 'xu-ly-ticket',
    EXPORT_TICKET: 'export-ticket',
    TRUNG_TAM_HO_TRO: 'trung-tam-ho-tro',
    XEM_DANH_SACH_HO_TRO: 'xem-danh-sach-ho-tro',
    THEM_MOI_HO_TRO: 'them-moi-ho-tro',
    XEM_CHI_TIET_HO_TRO: 'xem-chi-tiet-ho-tro',
    SUA_HO_TRO: 'sua-ho-tro',
    XOA_HO_TRO: 'xoa-ho-tro',
    XU_LY_HO_TRO: 'xu-ly-ho-tro',
    EXPORT_TICKET_TRUNG_TAM_HO_TRO: 'export-ticket-trung-tam-ho-tro',
    QUAN_LY_CHUYEN_NHUONG:'quan-ly-chuyen-nhuong',
    XU_LY_PHAN_HOI: 'xu-ly-phan-hoi',
    PHONG_BAN_XU_LY: 'phong-ban-xu-ly',
    QUAN_LY_DANH_MUC: 'quan-ly-danh-muc',
    PHONG_BAN_NHAP_GIAI_PHAP: 'phong-ban-nhap-giai-phap',
    XEM_DANH_SACH_PHONG_BAN_XU_LY: 'xem-danh-sach-phong-ban-xu-ly',
    NHAP_GIAI_PHAP_PHONG_BAN_XU_LY: 'nhap-giai-phap-phong-ban-xu-ly',
    QUAN_LY_PHONG_BAN: 'quan-ly-phong-ban',
    QUAN_LY_DU_LIEU: 'quan-ly-du-lieu',
    CHUONG_TRINH_HOAN_TIEN: 'chuong-trinh-hoan-tien',
    BANG_BAO_LOI_XU_LY_PHAN_HOI: 'bang-bao-loi-xu-ly-phan-hoi',
    DUYET_TRA_GOP: 'duyet-tra-gop',
    SU_KIEN_NOI_BO: 'su-kien-noi-bo',
    QUAN_LY_DIEU_KHOAN_CHINH_SACH: 'quan-ly-dieu-khoan-chinh-sach',
    BUOI_KIEM_TRA: 'buoi-kiem-tra',
    QUAN_LY_DAO_TAO: 'quan-ly-dao-tao',

    //Test dau vao
    QUAN_LY_ADMIN :'quan-ly-admin',
    NHAN_SU_ADMIN :'nhan-su-admin',
    QUAN_LY_TAC_VU :'quan-ly-tac-vu',
    LICH_TRUC_TEST_DAU_VAO :'lich-truc-test-dau-vao',
    TAC_VU_ADMIN_CA_NHAN :'tac-vu-admin-ca-nhan',
    GIAI_DAU :'giai-dau',
    THONG_TIN_CITIME: 'thong-tin-citime',
    CHUONG_TRINH_QUAY_THUONG : "chuong-trinh-quay-thuong",

    XU_LY_YEU_CAU : "xu-ly-yeu-cau",
    CO_SO_VAT_CHAT : "co-so-vat-chat",
    GIA_VON : "gia-von",
    DUYET_DON: 'duyet-don',
    QUAN_LY_LO_TRINH:'quan-ly-lo-trinh',
    BIEU_DO: 'bieu-do',
    BAO_CAO_TST: 'bao-cao-tst',
    QUAN_LY_DON_VI_TST: 'quan-ly-don-vi-tst',
    QUAN_LY_NOTIFICATION: 'quan-ly-notification',
    DON_DE_XUAT: 'don-de-xuat',
    NHAN_SU_CHAM_CONG: 'nhan-su-cham-cong',
    QUAN_LY_NHAN_SU_TRU_SO_TONG: 'quan-ly-nhan-su-tru-so-tong',
    CO_SO_VAT_CHAT_TRU_SO_TONG: 'co-so-vat-chat-tru-so-tong',
    QUAN_LY_DON_VI_TRU_SO_TONG: 'quan-ly-don-vi',
    QUAN_LY_DAO_TAO_UNG_VIEN_GIANG_VIEN: 'quan-ly-dao-tao-ung-vien-giang-vien',
    QUAN_LY_KENH_LK_TRUONG: 'quan-ly-kenh-lk-truong',
    PHAN_QUYEN_CHUONG_TRINH: 'phan-quyen-chuong-trinh',
    QUAN_LY_CHAM_CONG: 'quan-ly-cham-cong',
    CHUONG_TRINH_TVV_DINH_KY: 'chuong-trinh-tvv-dinh-ky',
    CHUONG_TRINH_VINH_DANH: 'chuong-trinh-vinh-danh',
    LICH_SU_CUOC_GOI: 'lich-su-cuoc-goi',
    BAO_CAO_CUOC_GOI: 'bao-cao-cuoc-goi',
    REPORT_CA_TEST: 'report-ca-test',
    BAO_CAO_DOANH_THU_DOI: 'bao-cao-doanh-thu-doi',
    BAO_CAO_DOANH_THU_TAI_DIEM : 'bao-cao-doanh-thu-tai-diem',
    BAO_CAO_TVV: 'bao-cao-tvv',
    QUAN_LY_VON_GOP_THUC_TE: 'quan-ly-von-gop-thuc-te',
    CHI_VAN_PHONG: 'chi-van-phong',
    BAO_CAO_TAI_CHINH_VP: 'bao-cao-tai-chinh-vp',
    QUAN_LY_CHI_PHI_SACH: 'quan-ly-chi-phi-sach',
    QUAN_LY_CHI_PHI_BOX: 'quan-ly-chi-phi-box',
    QUAN_LY_DOANH_THU_THUAN: 'quan-ly-doanh-thu-thuan',
    BAO_CAO_CO_DONG: 'bao-cao-co-dong',
    SUA_DIEM_DANH: 'sua-diem-danh',
    BAO_CAO_DAO_TAO: 'bao-cao-dao-tao',
    NGUON_LUC_MO_LOP: 'nguon-luc-mo-lop',
    QUAN_LY_BOT: 'quan-ly-bot',
    NHAP_DIEM_KIEM_TRA: 'nhap-diem-kiem-tra',
    LICH_SU_KIEM_TRA: 'lich-su-kiem-tra',
    CHI_TIET_BUOI_KIEM_TRA: 'chi-tiet-buoi-kiem-tra',
    LICH_HOC: 'lich-hoc',
    QUAN_LY_KPI_NHAN_SU: 'quan-ly-kpi-nhan-su',
    BAO_CAO_DIGITAL: 'bao-cao-digital',
    TAC_VU_XEP_LOP_CA_NHAN: 'tac-vu-xep-lop-ca-nhan',
    DIEU_PHOI_LOP_HOC: 'dieu-phoi-lop-hoc',
    QUAN_LY_TAC_VU_DIEU_PHOI_LOP_HOC: 'quan-ly-tac-vu-dieu-phoi-lop-hoc',
    XEM_DANH_SACH_TAC_VU_DIEU_PHOI_LOP_HOC: 'xem-danh-sach-tac-vu-dieu-phoi-lop-hoc',
    XEM_CHI_TIET_TAC_VU_LOP_HOC: 'xem-chi-tiet-tac-vu-dieu-phoi-lop-hoc',
    HUY_TAC_VU_DIEU_PHOI_LOP_HOC: 'huy-tac-vu-dieu-phoi-lop-hoc',
    THEM_MOI_TAC_VU_DIEU_PHOI_LOP_HOC: 'them-moi-tac-vu-dieu-phoi-lop-hoc',
    EXPORT_TAC_VU_DIEU_PHOI_LOP_HOC: 'export-tac-vu-dieu-phoi-lop-hoc',
    DIEU_PHOI_TAC_VU_DIEU_PHOI_LOP_HOC: 'dieu-phoi-tac-vu-dieu-phoi-lop-hoc',
    TRAI_PHIEU: 'trai-phieu',
    APP_GAME_CAMBRIDGE: 'app-game-cambridge',
    QUAN_LY_QUA_TANG: 'quan-ly-qua-tang',
    WEB_GENZ: 'web-genz',
    BAO_CAO_XUAT_NHAP_KHO: 'bao-cao-xuat-nhap-kho',
    QUAN_LY_KHO_QUA: 'quan-ly-kho-qua',
    UNG_VIEN: 'ung-vien',
    NHOM_GIANG_VIEN: 'nhom-giang-vien',
}

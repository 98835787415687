import moment from 'moment-timezone';
import writeXlsxFile from "write-excel-file";

export function pluralize(time, label) {
    if (time === 1) {
        return time + label;
    }
    return time + label + 's';
}

export function timeAgo(time) {
    const between = Date.now() / 1000 - Number(time);
    if (between < 3600) {
        return pluralize(~~(between / 60), ' minute');
    } else if (between < 86400) {
        return pluralize(~~(between / 3600), ' hour');
    } else {
        return pluralize(~~(between / 86400), ' day');
    }
}

/* Number formating*/
export function numberFormatter(num, digits) {
    const si = [
        {value: 1E18, symbol: 'E'},
        {value: 1E15, symbol: 'P'},
        {value: 1E12, symbol: 'T'},
        {value: 1E9, symbol: 'G'},
        {value: 1E6, symbol: 'M'},
        {value: 1E3, symbol: 'k'},
    ];
    for (let i = 0; i < si.length; i++) {
        if (num >= si[i].value) {
            return (num / si[i].value + 0.1).toFixed(digits).replace(/\.0+$|(\.[0-9]*[1-9])0+$/, '$1') + si[i].symbol;
        }
    }
    return num.toString();
}

export function toThousandFilter(num) {
    return (+num || 0).toString().replace(/^-?\d+/g, m => m.replace(/(?=(?!\b)(\d{3})+$)/g, ','));
}

export function uppercaseFirst(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export function formatDate(value) {
    if (value) {
        return moment(String(value)).format('DD-MM-YYYY')
    }
}

export function formatMonth(value) {
    if (value) {
        return moment(String(value)).format('MM')
    }
}

export function formatYearMonth(value) {
    if (value) {
        return moment(String(value)).format('YYYY-MM')
    }
}

export function formatYear(value) {
    if (value) {
        return moment(String(value)).format('YYYY')
    }
}

export function formatDateTime(value) {
    if (value) {
        return moment(String(value)).local().format('DD-MM-YYYY HH:mm:ss')
    }
}

export function formatDateTimeAsia(value) {
    if (value) {
        return moment(String(value)).format('DD-MM-YYYY HH:mm:ss')
    }
}

export function formatDateTimeVietnam(value) {
    if (value) {
        return moment(String(value)).format('HH:mm:ss DD-MM-YYYY')
    }
}

export function formatTime(value) {
    if (value) {
        return moment('2022-10-10 ' + String(value)).format('HH:mm')
    }
}
export function formatTimeNew(value) {
    if (value) {
        return moment(String(value)).format('HH:mm:ss')

    }
}


export function convertStringWithHtml(value) {
    if (value) {
        return value.replace(/<\/?[a-z][a-z0-9]*[^<>]*>/ig, "")
    }
}

export function formatDateMonth(value) {
    if (value) {
        return moment(String(value)).utc().format('DD/MM')
    }
}

export function formatMonthYear(value) {
    if (value) {
        return moment(String(value)).format('MM-YYYY')
    }
}

export function formatPrice(value) {
    if (value == '' || value == null) {
        return '0đ'
    }
    let val = (value / 1).toFixed(0).replace('.', ',')
    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") + 'đ'
}

export function getCategoryTicket(value) {
    const tickets = [
        'Bảo hành',
        'Bảo lưu',
        'Khiếu nại',
        'Hỗ trợ học tập',
        'Rút tiền',
        'Đề xuất',
        'Khác'
    ]
    if (value) {
        const result = tickets.filter((item, key) => {
            if (key + 1 == value) {
                return item;
            }
        });

        return result.toString();
    }
    return '';
}

export function getStatusTicket(value) {
    const status = [
        'Hủy',
        'Chờ xử lý',
        'Đang xử lý',
        'Hoàn thành',
    ]
    if (value) {
        const result = status.filter((item, key) => {
            if (key == value) {
                return item;
            }
        });

        return result.toString();
    }
    return '';
}

export function ChangeToSlug(text) {
    let slug;

    //Đổi chữ hoa thành chữ thường
    slug = text.toLowerCase();

    //Đổi ký tự có dấu thành không dấu
    slug = slug.replace(/á|à|ả|ạ|ã|ă|ắ|ằ|ẳ|ẵ|ặ|â|ấ|ầ|ẩ|ẫ|ậ/gi, 'a');
    slug = slug.replace(/é|è|ẻ|ẽ|ẹ|ê|ế|ề|ể|ễ|ệ/gi, 'e');
    slug = slug.replace(/i|í|ì|ỉ|ĩ|ị/gi, 'i');
    slug = slug.replace(/ó|ò|ỏ|õ|ọ|ô|ố|ồ|ổ|ỗ|ộ|ơ|ớ|ờ|ở|ỡ|ợ/gi, 'o');
    slug = slug.replace(/ú|ù|ủ|ũ|ụ|ư|ứ|ừ|ử|ữ|ự/gi, 'u');
    slug = slug.replace(/ý|ỳ|ỷ|ỹ|ỵ/gi, 'y');
    slug = slug.replace(/đ/gi, 'd');
    //Xóa các ký tự đặt biệt
    slug = slug.replace(/\`|\~|\!|\@|\#|\||\$|\%|\^|\&|\*|\(|\)|\+|\=|\,|\.|\/|\?|\>|\<|\'|\"|\:|\;|\]|\[|_/gi, '');
    //Đổi khoảng trắng thành ký tự gạch ngang
    slug = slug.replace(/ /gi, "-");
    //Đổi nhiều ký tự gạch ngang liên tiếp thành 1 ký tự gạch ngang
    //Phòng trường hợp người nhập vào quá nhiều ký tự trắng
    slug = slug.replace(/\-\-\-\-\-/gi, '-');
    slug = slug.replace(/\-\-\-\-/gi, '-');
    slug = slug.replace(/\-\-\-/gi, '-');
    slug = slug.replace(/\-\-/gi, '-');
    //Xóa các ký tự gạch ngang ở đầu và cuối
    slug = '@' + slug + '@';
    slug = slug.replace(/\@\-|\-\@|\@/gi, '');
    //In slug ra textbox có id “slug”
    return slug;
}

export function removeVietnameseTones(str) {
  str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
  str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
  str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
  str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
  str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
  str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
  str = str.replace(/đ/g, "d");
  str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, "A");
  str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, "E");
  str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, "I");
  str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, "O");
  str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, "U");
  str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, "Y");
  str = str.replace(/Đ/g, "D");
  // Some system encode vietnamese combining accent as individual utf-8 characters
  // Một vài bộ encode coi các dấu mũ, dấu chữ như một kí tự riêng biệt nên thêm hai dòng này
  str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, ""); // ̀ ́ ̃ ̉ ̣  huyền, sắc, ngã, hỏi, nặng
  str = str.replace(/\u02C6|\u0306|\u031B/g, ""); // ˆ ̆ ̛  Â, Ê, Ă, Ơ, Ư
  // Remove extra spaces
  // Bỏ các khoảng trắng liền nhau
  str = str.replace(/ + /g, " ");
  str = str.trim();
  // Remove punctuations
  // Bỏ dấu câu, kí tự đặc biệt
  str = str.replace(/!|@|%|\^|\*|\(|\)|\+|\=|\<|\>|\?|\/|,|\.|\:|\;|\'|\"|\&|\#|\[|\]|~|\$|_|`|-|{|}|\||\\/g, " ");
  return str;
}

export function formatVND(value) {
    if (value === '' || value == null) {
        value = 0;
    }
    return new Intl.NumberFormat('de-DE', {style: 'currency', currency: 'VND'}).format(value)
}

export function exportExcel(header, data, fileName, checkStt = false) {
    let dataExcel = data.map((item, key) => {
        let row = []
        if (checkStt) {
            row.push({ value: key + 1 })
            // header.shift();
        }
        header.map((itemHeader) => {
            if (itemHeader.key !== 'stt') {
                let itemRow = item[itemHeader.key]
                row.push({value: itemRow})
            }
        })
        return row
    });
    let dataExport = [header, ...dataExcel]
    writeXlsxFile(dataExport, {fileName})
}

export function exportDontNeedHeader(data, fileName) {
  let header = [];
  for (const [key, value] of Object.entries(data[0])) {
    header.push({
      key : key,
      value : key
    });
  }
  let dataExcel = data.map((item, key) => {
      let row = []
      header.map((itemHeader) => {
          if (itemHeader.key != 'stt') {
              let itemRow = item[itemHeader.key]
              row.push({value: itemRow})
          }
      })
      return row
  });
  let dataExport = [header, ...dataExcel]
  writeXlsxFile(dataExport, {fileName})
}

export function exportExcelMultipleSheet(data_sheet, fileName, checkStt = false) {
    let dataExport = [];
    let sheets = [];
    data_sheet.map((item, key) => {
        let dataExcel = item.data.map((item_each, key) => {
            let row = []
            if (checkStt) {
                row.push({ value: key + 1 })
            }
            item.header.map((itemHeader) => {
                if (itemHeader.key != 'stt') {
                    let itemRow = item_each[itemHeader.key]
                    row.push({value: itemRow})
                }
            })
            return row
        });
        let dataExportEach = [item.header, ...dataExcel];
        sheets.push(item.sheet);
        dataExport.push(dataExportEach);
    });
    writeXlsxFile(dataExport, {fileName,sheets})
}

export const HEADER_ROW_EXPORT_ERROR_LIST_STUDY_TURN= [
    {
      key: 'center_name',
      value: 'Trung tâm',
    },
    {
      key: 'branch_name',
      value: 'Chi nhánh học',
    },
    {
      key: 'area_name',
      value: 'Khu vực',
    },
    {
      key: 'course_type',
      value: 'Loại khóa học',
    },
    {
      key: 'course_name',
      value: 'Khóa học',
    },
    {
      key: 'name',
      value: 'Lớp học',
    },
    {
      key: 'teacher_name',
      value: 'Giảng viên',
    },
    {
      key: 'user_name',
      value: 'Học viên',
    },
    {
      key: 'user_phone',
      value: 'Số điện thoại',
    },
    {
      key: 'day_number',
      value: 'Buổi học',
    },
    {
      key: 'is_exam',
      value: 'Loại buổi học',
    },
    {
      key: 'attendance',
      value: 'Điểm danh',
    },
    {
      key: 'is_homework',
      value: 'Trạng thái BTVN',
    },
    {
      key: 'low_score',
      value: 'Kết quả BTVN thấp',
    },
    {
      key: 'teacher_feedback',
      value: 'Đánh giá của GV',
    },
    {
      key: 'student_feedback',
      value: 'Đánh giá của HV',
    },
    {
      key: 'created_at',
      value: 'Thời gian học',
    },
    {
      key: 'is_gift',
      value: 'Tặng quà',
    },
    {
        key: 'group_name',
        value: 'Nhóm giảng viên',
    },
  ]

  export const HEADER_ROW_EXPORT_ERROR_LIST_STUDENT = [
    {
      key: 'center_name',
      value: 'Trung tâm',
    },
    {
      key: 'branch_name',
      value: 'Chi nhánh học',
    },
    {
      key: 'area_name',
      value: 'Khu vực',
    },
    {
      key: 'course_type',
      value: 'Loại khóa học',
    },
    {
      key: 'course_name',
      value: 'Khóa học',
    },
    {
      key: 'name',
      value: 'Lớp học',
    },
    {
      key: 'user_name',
      value: 'Học viên',
    },
    {
      key: 'user_phone',
      value: 'Số điện thoại',
    },
    {
      key: 'position',
      value: 'Vị trí',
    },
    {
      key: 'level',
      value: 'Level',
    },
    {
      key: 'rank',
      value: 'Rank team',
    },
    {
      key: 'learning_process',
      value: 'Tiến trình học',
    },
    {
      key: 'session_number',
      value: 'Số buổi đi học',
    },
    {
      key: 'do_homework_number',
      value: 'Số buổi làm BTVN',
    },
    {
      key: 'gift_count',
      value: 'Số buổi được tặng quà',
    },
    {
      key: 'mid_score',
      value: 'Điểm giữa khóa',
    },
    {
      key: 'final_score',
      value: 'Điểm cuối khóa',
    },
    {
      key: 'course_register_time',
      value: 'Thời gian đăng ký khóa học',
    },
    {
      key: 'contract_order',
      value: 'Thứ tự khóa học trong HĐ',
    },
    {
      key: 'course_id',
      value: 'ID khóa học',
    },
    {
      key: 'book_material',
      value: 'NVL_Sách',
    },
    {
      key: 'certificate_material',
      value: 'NVL_Chứng chỉ',
    },
    {
      key: 'output_bonus_material',
      value: 'NVL_Thưởng đầu ra',
    },
    {
      key: 'party_material',
      value: 'NVL_Liên hoan KG/KT',
    },
    {
      key: 'stationery_material',
      value: 'NVL_Văn phòng phẩm',
    },
    {
      key: 'box_material',
      value: 'NVL_Box',
    },
    {
      key: 'teacher_labor',
      value: 'NC_Giáo viên',
    },
    {
      key: 'open_end_labor',
      value: 'NC_Khai giảng & Kết thúc',
    },
    {
      key: 'demo_labor',
      value: 'NC_Demo',
    },
    {
      key: 'end_gift_labor',
      value: 'NC_Quà kết thúc',
    },
    {
      key: 'teaching_assistant_labor',
      value: 'NC_Trợ giảng',
    }
  ]
    export const HEADER_ROW_EXPORT_CATEGORY_0 = [
      {
        key: 'user_id',
        value: 'Mã nhân sự',
      },
      {
        key: 'name',
        value: 'Họ và tên nhân sự',
      },
      {
        key: 'reason',
        value: 'Lý do nghỉ phép',
      },
      {
        key: 'status',
        value: 'Trạng thái',
      },
      {
        key: 'general_leave',
        value: 'Tổng số công nghỉ phép',
      },
      {
        key: 'start_date',
        value: 'Ngày bắt đầu nghỉ',
      },
      {
        key: 'end_date',
        value: 'Nghỉ đến ngày',
      },
      {
        key: 'start_shift_off',
        value: 'Buổi bắt đầu nghỉ phép',
      },
      {
        key: 'id_user_handler',
        value: 'Mã nhân sự xử lý',
      },
      {
        key: 'name_user_handler',
        value: 'Tên nhân sự xử lý',
      },
      {
        key: 'reason_for_refusal',
        value: 'Lý do từ chối',
      },
      {
          key: 'text_type_leave',
          value: 'Loại nghỉ phép',
      },
      {
        key: 'name_department',
        value: 'Phòng ban',
      },
      {
        key: 'created_at_convert',
        value: 'Thời gian đề xuất',
      },
      {
        key: 'updated_at_convert',
        value: 'Thời gian xử lý đơn',
      },
    ]

    export const HEADER_ROW_EXPORT_CATEGORY_1 = [
      {
        key: 'user_id',
        value: 'Mã nhân sự',
      },
      {
        key: 'name',
        value: 'Họ và tên nhân sự',
      },
      {
        key: 'reason',
        value: 'Lý do tăng ca',
      },
      {
        key: 'status',
        value: 'Trạng thái',
      },
      {
        key: 'start_date',
        value: 'Ngày bắt tăng ca',
      },
      {
        key: 'time_checkin',
        value: 'Giờ bắt đầu đề xuất',
      },
      {
        key: 'time_checkout',
        value: 'Giờ kết thúc đề xuất',
      },
      {
        key: 'fingerprint_attendance_start',
        value: 'Giờ băt đầu thực tế',
      },
      {
        key: 'fingerprint_attendance_end',
        value: 'Giờ kết thúc thực tế',
      },
      {
        key: 'thoi_gian_tang_ca',
        value: 'Thời gian tăng ca',
      },
      {
        key: 'text_type_arise',
        value: 'Loại tăng ca',
      },
      {
        key: 'overtime_results',
        value: 'Kết quả tăng ca',
      },
      {
        key: 'id_user_handler',
        value: 'Mã nhân sự xử lý',
      },
      {
        key: 'name_user_handler',
        value: 'Tên nhân sự xử lý',
      },
      {
        key: 'reason_for_refusal',
        value: 'Lý do từ chối',
      },
      {
        key: 'name_department',
        value: 'Phòng ban',
      },
      {
        key: 'created_at_convert',
        value: 'Thời gian đề xuất',
      },
      {
        key: 'updated_at_convert',
        value: 'Thời gian xử lý đơn',
      },
    ]

    export const HEADER_ROW_EXPORT_CATEGORY_2 = [
      {
        key: 'user_id',
        value: 'Mã nhân sự',
      },
      {
        key: 'name',
        value: 'Họ và tên nhân sự',
      },
      {
        key: 'reason',
        value: 'Lý do đổi ca',
      },
      {
        key: 'status',
        value: 'Trạng thái',
      },
      {
        key: 'start_date',
        value: 'Ngày đổi ca',
      },
      {
        key: 'current_working_hours',
        value: 'Ca hiện tại',
      },
      {
        key: 'current_working_change',
        value: 'Ca thay đổi',
      },
      {
        key: 'id_user_handler',
        value: 'Mã nhân sự xử lý',
      },
      {
        key: 'name_user_handler',
        value: 'Tên nhân sự xử lý',
      },
      {
        key: 'reason_for_refusal',
        value: 'Lý do từ chối',
      },
      {
        key: 'name_department',
        value: 'Phòng ban',
      },
      {
        key: 'created_at_convert',
        value: 'Thời gian đề xuất',
      },
      {
        key: 'updated_at_convert',
        value: 'Thời gian xử lý đơn',
      },
    ]

    export const HEADER_ROW_EXPORT_CATEGORY_3 = [
      {
        key: 'user_id',
        value: 'Mã nhân sự',
      },
      {
        key: 'name',
        value: 'Họ và tên nhân sự',
      },
      {
        key: 'reason',
        value: 'Lý do bổ sung',
      },
      {
        key: 'status',
        value: 'Trạng thái',
      },
      {
        key: 'start_date',
        value: 'Ngày bổ sung',
      },
      {
        key: 'time_checkin',
        value: 'Thời gian bắt đầu',
      },
      {
        key: 'time_checkout',
        value: 'Thời gian kết thúc',
      },
      {
        key: 'id_user_handler',
        value: 'Mã nhân sự xử lý',
      },
      {
        key: 'name_user_handler',
        value: 'Tên nhân sự xử lý',
      },
      {
        key: 'reason_for_refusal',
        value: 'Lý do từ chối',
      },
      {
        key: 'name_department',
        value: 'Phòng ban',
      },
      {
        key: 'created_at_convert',
        value: 'Thời gian đề xuất',
      },
      {
        key: 'updated_at_convert',
        value: 'Thời gian xử lý đơn',
      },
    ]
    export const HEADER_ROW_EXPORT_CANDIDATE_TEACHER= [
      {
        key: 'code',
        value: 'Mã giảng viên',
      },
      {
        key: 'name',
        value: 'Họ và tên',
      },
      {
        key: 'phone',
        value: 'Số điện thoại',
      },
      {
        key: 'email',
        value: 'Email'
      },
      {
        key: 'area',
        value: 'Khu vực'
      },
      {
        key: 'center_names',
        value: 'Trung tâm'
      },
      {
        key: 'created_at',
        value: 'Thời gian tạo giảng viên'
      },
      {
        key: 'deadline_format',
        value: 'Thời gian deadline'
      },
      {
        key: 'deadline_text',
        value: 'Thời gian học đào tạo'
      },
      {
        key: 'status_teacher_name',
        value: 'Trạng thái giảng viên'
      },
      {
        key: 'status_candidate_name_1',
        value: 'Đào tạo gia nhập'
      },
      {
          key: 'status_candidate_name_2',
          value: 'Kỹ năng giảng dạy'
      }
    ]

    export const HEADER_ROW_EXPORT_STUDENT_REGISTERR_GENZ= [
      
    ]




import ApiService from "@/core/services/api.service";

export const PATH_TASK_MANAGEMENT = "ticket-classroom-arrangement";
export const GET_TASK_MANAGEMENT_LIST = "GET_TASK_MANAGEMENT_LIST";
export const CREATE_TASK_MANAGEMENT = "CREATE_TASK_MANAGEMENT";
export const TASK_MANAGEMENTS_DETAIL = "TASK_MANAGEMENTS_DETAIL";
export const DELETE_TASK_MANAGEMENT = "ticket-classroom-arrangement/delete-ticket";
export const EXPORT_TASK_MANAGEMENT = "ticket-classroom-arrangement/export";
export const PATH_PERSON_IN_CHARGE = "ticket-classroom-arrangement/list-person-in-charge";
export const GET_ALL_PERSON_IN_CHARGE = "GET_ALL_PERSON_IN_CHARGE";
export const GET_LIST_PERSON_IN_CHARGE_ACTIVE = "GET_LIST_PERSON_IN_CHARGE_ACTIVE";
export const GET_BRANCHES = "branches";
export const GET_CENTER_TAG_E = 'centers'
export const GET_LIST_TASK_MANAGEMENT_PERSON = "GET_LIST_TASK_MANAGEMENT_PERSON";
export const GET_LIST_COURSE_OF_STUDENT = "GET_LIST_COURSE_OF_STUDENT";
export const PATH_GET_LIST_COURSE_OF_STUDENT = "ticket-classroom-arrangement/list-student-register-course";
export const WORK_COORDINATION = "ticket-classroom-arrangement/work-coordination"
export const GET_LIST_CUSTOMER = "ticket-classroom-arrangement/find-user"
export const GET_DETAIL_TICKET_CLASS = 'ticket-classroom-arrangement';
export const LIST_CLASSROOM = 'ticket-classroom-arrangement/list-classroom'
export const GET_LIST_BRANCH_BY_TICKET = 'ticket-classroom-arrangement/list-branch-by-ticket';
//Tác vụ điều phối lớp học cá nhân
export const GET_ALL_LIST_TASK = "GET_ALL_LIST_TASK";
export const GET_LIST_CALENDAR_OF_CENTER = "classroom-time-manager/by-center";
export const GET_LIST_NEXT_COURSE = "ticket-classroom-arrangement/list-student-register-course";
export const SAVE_RESULT_TASK = "SAVE_RESULT_TASK";
export const GET_LOG_HISTORY = "ticket-classroom-arrangement/logs";
export const GET_SCHEDULE_LIST = 'classroom-time-manager/list';
export const GET_COURSE_LIST = 'ticket-classroom-arrangement/course-list';
export const GET_CLASS_LIST = 'ticket-classroom-arrangement/class-list-by-center-status-code';
export const GET_FILTER_CLASS_BY_NAME = 'classrooms/filter-by-name'

//Comment
export const GET_COMMENT = "ticket-classroom-arrangement/get-comment-ticket"
export const CREATE_COMMENT = "ticket-classroom-arrangement/store-comment"
export const UPDATE_COMMENT = "ticket-classroom-arrangement/update-comment"
export const DELETE_COMMENT = "ticket-classroom-arrangement/deleted-comment"

const state = {};

const getters = {};

const actions = {
    [GET_TASK_MANAGEMENT_LIST](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query(PATH_TASK_MANAGEMENT, payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response);
            });
        });
    },
    [GET_LIST_CUSTOMER](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query(GET_LIST_CUSTOMER, payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response);
            });
        });
    },
    [TASK_MANAGEMENTS_DETAIL](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query(PATH_TASK_MANAGEMENT, payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response);
            });
        });
    },
    [GET_LIST_BRANCH_BY_TICKET](context, id) {
        return new Promise((resolve, reject) => {
            ApiService.get(GET_LIST_BRANCH_BY_TICKET, id).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response);
            })
        });
    },
    [GET_ALL_PERSON_IN_CHARGE](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query(PATH_PERSON_IN_CHARGE, payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response);
            });
        });
    },
    [GET_LIST_PERSON_IN_CHARGE_ACTIVE](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query(PATH_PERSON_IN_CHARGE, payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response);
            });
        });
    },
    [GET_ALL_PERSON_IN_CHARGE](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query(PATH_PERSON_IN_CHARGE, payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response);
            });
        });
    },
    [GET_LIST_TASK_MANAGEMENT_PERSON](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query(PATH_TASK_MANAGEMENT, payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response);
            });
        });
    },
    [GET_LIST_COURSE_OF_STUDENT](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query(PATH_GET_LIST_COURSE_OF_STUDENT, payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response);
            });
        });
    },
    [GET_BRANCHES](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query(GET_BRANCHES, payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response);
            });
        });
    },
    [GET_CENTER_TAG_E](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query(GET_CENTER_TAG_E, payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response);
            });
        });
    },
    [CREATE_TASK_MANAGEMENT](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(PATH_TASK_MANAGEMENT, payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response);
            });
        });
    },
    [WORK_COORDINATION](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(WORK_COORDINATION, payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response);
            });
        });
    },
    [DELETE_TASK_MANAGEMENT](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(`${DELETE_TASK_MANAGEMENT}/${payload.id}`, payload.params).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response);
            });
        });
    },
    [EXPORT_TASK_MANAGEMENT](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query(EXPORT_TASK_MANAGEMENT, payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response);
            });
        });
    },

    // Tác vụ điều phối lớp học cá nhân
    [GET_ALL_LIST_TASK](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query(PATH_TASK_MANAGEMENT, payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response);
            });
        });
    },
    [GET_DETAIL_TICKET_CLASS](context, id) {
        return new Promise((resolve, reject) => {
            ApiService.get(GET_DETAIL_TICKET_CLASS, id).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response);
            });
        });
    },
    [LIST_CLASSROOM](context, id) {
        return new Promise((resolve, reject) => {
            ApiService.get(LIST_CLASSROOM, id).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response);
            });
        });
    },
    [GET_LIST_CALENDAR_OF_CENTER](context, id) {
        return new Promise((resolve, reject) => {
            ApiService.get(GET_LIST_CALENDAR_OF_CENTER, id).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response);
            });
        });
    },
    [GET_LIST_NEXT_COURSE](context, id) {
        return new Promise((resolve, reject) => {
            ApiService.get(GET_LIST_NEXT_COURSE, id).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response);
            });
        });
    },
    [SAVE_RESULT_TASK](context,payload) {
        return new Promise((resolve, reject) => {
            ApiService.update('ticket-classroom-arrangement', payload.id, payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response);
            });
        });
    },
    [GET_LOG_HISTORY](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query(GET_LOG_HISTORY+`/${payload.id}`, payload.payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response);
            });
        });
    },
    [GET_SCHEDULE_LIST](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query(GET_SCHEDULE_LIST, payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response);
            });
        });
    },
    [GET_COURSE_LIST](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query(GET_COURSE_LIST, payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response);
            });
        });
    },
    [GET_CLASS_LIST](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query(GET_CLASS_LIST, payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response);
            });
        });
    },
    [GET_FILTER_CLASS_BY_NAME](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query(GET_FILTER_CLASS_BY_NAME, payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response);
            });
        });
    },
    [GET_COMMENT](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query(`${GET_COMMENT}`, payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response);
            });
        });
    },
    [CREATE_COMMENT](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(CREATE_COMMENT, payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response);
            });
        });
    },
    [UPDATE_COMMENT](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(UPDATE_COMMENT, payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response);
            });
        });
    },
    [DELETE_COMMENT](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.delete(`${DELETE_COMMENT}`, payload.id).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response);
            });
        });
    },
};


const mutations = {};

export default {
    state,
    actions,
    mutations,
    getters
};

import Vue from "vue";
import Router from "vue-router";
import {ROLE} from './role';
import {PERMISSION} from './permission';

Vue.use(Router);

export default new Router({
    mode: "history",
    routes: [
        {
            path: "/",
            redirect: "/dashboard",
            component: () => import("@/view/layout/Layout"),
            children: [
                {
                    path: "/dashboard",
                    name: "dashboard",
                    meta: {authorize: 'ALL'},
                    component: () => import("@/view/pages/Dashboard.vue"),
                },
                {
                    path: "/release-note",
                    name: "release-note",
                    meta: {authorize: 'ALL'},
                    component: () => import("@/view/pages/setting/ReleaseNote.vue"),
                },
                {
                    path: "/employees",
                    name: "user-index",
                    meta: {authorize: PERMISSION.XEM_DANH_SACH_NHAN_SU_CO_SO},
                    component: () => import("@/view/pages/user/UserIndex.vue")
                },
                {
                    path: "/trai-phieu",
                    name: "BondManagementList",
                    meta: {authorize: PERMISSION},
                    component: () => import("@/view/pages/baseoffice/bonds-management/BondManagementList.vue")
                },
                {
                    path: "/candidate",
                    name: "user-candidate",
                    meta: {authorize: PERMISSION.XEM_DANH_SACH_NHAN_SU_CO_SO},
                    component: () => import("@/view/pages/user/UserCandidate.vue")
                },
                {
                    path: "/training-manage",
                    name: "training-manage",
                    meta: {authorize: PERMISSION.CHUONG_TRINH_TVV_DINH_KY},
                    component: () => import("@/view/pages/user/TrainingManageCourse.vue")
                },
                {
                    path: "/honors",
                    name: "honors",
                    meta: {authorize: PERMISSION.CHUONG_TRINH_VINH_DANH},
                    component: () => import("@/view/pages/honors/honors.vue"),
                    children: [
                        {
                            path: "",
                            name: "honorsList",
                            meta: {authorize: PERMISSION.CHUONG_TRINH_VINH_DANH},
                            component: () => import("@/view/pages/honors/honorsList.vue")
                        },
                        {
                            path: "detail/:id",
                            name: "honor-details",
                            meta: {authorize: PERMISSION.CHUONG_TRINH_VINH_DANH},
                            component: () => import("@/view/pages/honors/honorDetail.vue")
                        },
                    ]
                },
                {
                    path: "/propose",
                    name: "list-propose-application",
                    meta: {authorize: PERMISSION.DON_DE_XUAT},
                    component: () => import("@/view/pages/request/ListProposeApplication.vue")
                },
                {
                    path: "/genz",
                    name: "gen-z",
                    meta: {authorize: PERMISSION.WEB_GENZ},
                    component: () => import("@/view/pages/genZ/index.vue"),
                },
                {
                    path: "/promotion-detail",
                    name: "promotion-detail",
                    meta: {authorize: PERMISSION.WEB_GENZ},
                    component: () => import("@/view/pages/genZ/PromotionDetail.vue")
                },
                {
                    path: "/management-kpi",
                    name: "ListManagementKpi",
                    meta: {authorize: PERMISSION.QUAN_LY_KPI_NHAN_SU},
                    component: () => import("@/view/pages/backoffice/managements/management-kpi/ListManagementKpi.vue")
                },
                {
                    path: "/account",
                    name: "account",
                    meta: {authorize: PERMISSION.QUAN_LY_TAI_KHOAN},
                    component: () => import("@/view/pages/user/UserAccount.vue")
                },
                {
                    path: "/account/create",
                    name: "account-create",
                    meta: {authorize: PERMISSION.QUAN_LY_TAI_KHOAN},
                    component: () => import("@/view/pages/user/AccountCreate.vue")
                },
                {
                    path: "/employee/create",
                    name: "user-create",
                    meta: {authorize: PERMISSION.THEM_NHAN_SU_CO_SO},
                    component: () => import("@/view/pages/user/UserCreate.vue")
                },
                {
                    path: "/account/edit/:id",
                    name: "account-edit",
                    meta: {authorize: PERMISSION.QUAN_LY_TAI_KHOAN},
                    component: () => import("@/view/pages/user/AccountEdit.vue")
                },
                {
                    path: "/employee/edit/:id",
                    name: "user-edit",
                    meta: {authorize: PERMISSION.SUA_NHAN_SU_CO_SO},
                    component: () => import("@/view/pages/user/UserEdit.vue")
                },
                {
                    path: "/account-type",
                    name: "account-type-index",
                    meta: {authorize: PERMISSION.QUAN_LY_TAI_KHOAN},
                    component: () => import("@/view/pages/account-type/AccountTypeIndex.vue")
                },
                {
                    path: "/account-type/create",
                    name: "account-type-create",
                    meta: {authorize: PERMISSION.QUAN_LY_TAI_KHOAN},
                    component: () => import("@/view/pages/account-type/AccountTypeCreate.vue")
                },
                {
                    path: "/account-type/edit/:id",
                    name: "account-type-edit",
                    meta: {authorize: PERMISSION.QUAN_LY_TAI_KHOAN},
                    component: () => import("@/view/pages/account-type/AccountTypeEdit.vue")
                },
                {
                    path: "/manager-bot",
                    name: "ManagerBot",
                    meta: {authorize: PERMISSION.QUAN_LY_TAI_KHOAN},
                    component: () => import("@/view/pages/account-type/ManagerBot.vue")
                },

                {
                    path: "/builder",
                    name: "builder",
                    meta: {authorize: [ROLE.ADMIN]},
                    component: () => import("@/view/pages/Builder.vue"),
                },
                {
                    path: "/department",
                    name: "department",
                    component: () => import("@/view/pages/department/Department.vue"),
                    children: [
                        {
                            path: "/",
                            name: "department-list",
                            meta: {authorize: PERMISSION.QUAN_LY_PHONG_BAN},
                            component: () => import("@/view/pages/department/DepartmentList.vue"),
                        },
                    ],
                },
                {
                    path: "/timekeeping",
                    name: "timekeeping",
                    component: () => import("@/view/pages/department/Department.vue"),
                    children: [
                        {
                            path: "/",
                            name: "timekeeping-list",
                            meta: {authorize: PERMISSION.QUAN_LY_CHAM_CONG},
                            component: () => import("@/view/pages/time-keeping/TimeKeeping.vue"),
                        },
                    ],
                },
                {
                    path: "/custom-plugins",
                    name: "plugins",
                    component: () => import("@/view/pages/plugins/Plugins.vue"),
                    children: [
                        {
                            path: "cropper",
                            name: "cropper",
                            component: () => import("@/view/pages/plugins/Cropper.vue"),
                        },
                        {
                            path: "treeselect",
                            name: "treeselect",
                            component: () => import("@/view/pages/plugins/Treeselect.vue"),
                        },
                    ],
                },
                {
                    path: "/profile",
                    name: "profile",
                    component: () => import("@/view/pages/profile/Profile.vue"),
                    children: [
                        {
                            path: "profile-1",
                            name: "profile-1",
                            meta: {authorize: 'ALL'},
                            component: () => import("@/view/pages/profile/Profile-1.vue"),
                        },
                        {
                            path: "profile-2",
                            name: "profile-2",
                            component: () => import("@/view/pages/profile/Profile-2.vue"),
                        },
                        {
                            path: "profile-3",
                            name: "profile-3",
                            component: () => import("@/view/pages/profile/Profile-3.vue"),
                        },
                        {
                            path: "profile-4",
                            name: "profile-4",
                            component: () => import("@/view/pages/profile/Profile-4.vue"),
                        },
                    ],
                },
                {
                    path: "/permission",
                    name: "permission",
                    component: () => import("@/view/pages/auth/permissions/Permission"),
                    children: [
                        {
                            name: "permission-list",
                            path: "/",
                            meta: {authorize: PERMISSION.QUAN_LY_TAI_KHOAN},
                            component: () =>
                                import("@/view/pages/auth/permissions/List-permission"),
                        },
                        {
                            name: "permission-create",
                            path: "create",
                            meta: {authorize: PERMISSION.QUAN_LY_TAI_KHOAN},
                            component: () =>
                                import("@/view/pages/auth/permissions/Create-permission"),
                        },
                        {
                            name: "permission-update",
                            path: "update/:id",
                            meta: {authorize: PERMISSION.QUAN_LY_TAI_KHOAN},
                            component: () =>
                                import("@/view/pages/auth/permissions/Edit-permission"),
                        },
                    ],
                },
                {
                    path: "/role",
                    name: "role",
                    component: () => import("@/view/pages/auth/roles/Role"),
                    children: [
                        {
                            name: "role-list",
                            path: "role-list",
                            meta: {authorize: PERMISSION.QUAN_LY_TAI_KHOAN},
                            component: () =>
                                import("@/view/pages/auth/roles/List-role"),
                        },
                        {
                            name: "role-create",
                            path: "create",
                            meta: {authorize: PERMISSION.QUAN_LY_TAI_KHOAN},
                            component: () =>
                                import("@/view/pages/auth/roles/Create-role"),
                        },
                        {
                            name: "role-update",
                            path: "update/:id",
                            meta: {authorize: PERMISSION.QUAN_LY_TAI_KHOAN},
                            component: () =>
                                import("@/view/pages/auth/roles/Edit-role"),
                        },
                        {
                            name: "role-detail",
                            path: "detail/:id",
                            meta: {authorize: PERMISSION.QUAN_LY_TAI_KHOAN},
                            component: () =>
                                import("@/view/pages/auth/roles/Detail-role"),
                        },
                    ],
                },
                {
                    path: "/support-notification",
                    name: "support-notification",
                    meta: {authorize: PERMISSION.QUAN_LY_TRUNG_TAM_HO_TRO},
                    component: () => import("@/view/pages/center-support/Notification"),
                },
                {
                    path: "/support-category",
                    name: "support-category",
                    meta: {authorize: PERMISSION.QUAN_LY_TRUNG_TAM_HO_TRO},
                    component: () => import("@/view/pages/center-support/Category"),
                },
                {
                    path: "/support-type",
                    name: "support-type",
                    meta: {authorize: PERMISSION.QUAN_LY_TRUNG_TAM_HO_TRO},
                    component: () => import("@/view/pages/center-support/Type"),
                },
                {
                    path: "/support-question",
                    name: "support-question",
                    meta: {authorize: PERMISSION.QUAN_LY_TRUNG_TAM_HO_TRO},
                    component: () => import("@/view/pages/center-support/Question"),
                    children: [
                        {
                            name: "support-question-list",
                            path: "/",
                            meta: {authorize: PERMISSION.QUAN_LY_DON_VI_SX},
                            component: () =>
                                import("@/view/pages/center-support/List-Question"),
                        },
                        {
                            name: "support-question-create",
                            path: "create",
                            meta: {authorize: PERMISSION.QUAN_LY_TRUNG_TAM_HO_TRO},
                            component: () =>
                                import("@/view/pages/center-support/Create-Question"),
                        },
                        {
                            name: "support-question-update",
                            path: "update/:id",
                            meta: {authorize: PERMISSION.QUAN_LY_TRUNG_TAM_HO_TRO},
                            component: () =>
                                import("@/view/pages/center-support/Edit-Question"),
                        },
                    ],
                },
                {
                    path: "/customer",
                    name: "customer",
                    component: () => import("@/view/pages/auth/users/User"),
                    children: [
                        {
                            name: "user-list",
                            path: "/",
                            meta: {authorize: PERMISSION.QUAN_LY_DON_VI_SX},
                            component: () =>
                                import("@/view/pages/auth/users/List-User"),
                        },
                        {
                            name: "customer-create",
                            path: "create",
                            meta: {authorize: PERMISSION.QUAN_LY_DON_VI_SX},
                            component: () =>
                                import("@/view/pages/auth/users/Create-User"),
                        },
                        {
                            name: "user-update",
                            path: "update/:id",
                            meta: {authorize: PERMISSION.QUAN_LY_DON_VI_SX},
                            component: () =>
                                import("@/view/pages/auth/users/Edit-User"),
                        },
                        {
                            name: "user-log-histories",
                            path: "log-history/:id",
                            meta: {authorize: PERMISSION.KHACH_HANG},
                            component: () =>
                                import("@/view/pages/auth/users/Log-Login-History"),
                        },
                        {
                            name: "user-log-action-histories",
                            path: "log-action-history/:id",
                            meta: {authorize: PERMISSION.KHACH_HANG},
                            component: () =>
                                import("@/view/pages/auth/users/Log-Action-History"),
                        },
                    ],
                },
                {
                    path: "/branch",
                    name: "branch",
                    component: () => import("@/view/pages/auth/users/User"),
                    children: [
                        {
                            path: "create",
                            name: "branch-create",
                            meta: {authorize: PERMISSION.QUAN_LY_DON_VI_SX},
                            component: () => import("@/view/pages/branch/BranchCreate")
                        },
                        {
                            path: "/",
                            name: "branch-index",
                            meta: {authorize: PERMISSION.QUAN_LY_DON_VI_SX},
                            component: () => import("@/view/pages/branch/BranchIndex")
                        },
                        {
                            path: "edit/:id",
                            name: "branch-edit",
                            meta: {authorize: PERMISSION.QUAN_LY_DON_VI_SX},
                            component: () => import("@/view/pages/branch/BranchEdit")
                        },
                    ],
                },
                {
                    path: "/office",
                    name: "office",
                    component: () => import("@/view/pages/auth/users/User"),
                    children: [
                        {
                            path: "/",
                            name: "office-index",
                            meta: {authorize: PERMISSION.QUAN_LY_DON_VI_HANH_CHINH},
                            component: () => import("@/view/pages/office/BranchIndex")
                        },
                        {
                            path: "edit/:id",
                            name: "office-edit",
                            meta: {authorize: PERMISSION.QUAN_LY_DON_VI_HANH_CHINH},
                            component: () => import("@/view/pages/office/BranchEdit")
                        },
                    ],
                },
                {
                    path: "/area",
                    name: "area",
                    component: () => import("@/view/pages/auth/users/User"),
                    children: [
                        {
                            path: "create",
                            name: "area-create",
                            meta: {authorize: PERMISSION.QUAN_LY_DON_VI_HANH_CHINH},
                            component: () => import("@/view/pages/area/AreaCreate")
                        },
                        {
                            path: "/",
                            name: "area-index",
                            meta: {authorize: PERMISSION.QUAN_LY_DON_VI_HANH_CHINH},
                            component: () => import("@/view/pages/area/AreaIndex")
                        },
                        {
                            path: "edit/:id",
                            name: "area-edit",
                            meta: {authorize: PERMISSION.QUAN_LY_DON_VI_HANH_CHINH},
                            component: () => import("@/view/pages/area/AreaEdit")
                        },
                    ],
                },
                {
                    path: "/course",
                    name: "course",
                    component: () => import("@/view/pages/auth/users/User"),
                    children: [
                        {
                            path: "create",
                            name: "course-create",
                            meta: {authorize: PERMISSION.QUAN_LY_DON_VI_SX},
                            component: () => import("@/view/pages/course/CourseCreate")
                        },
                        {
                            path: "/",
                            name: "course-index",
                            meta: {authorize: PERMISSION.QUAN_LY_DON_VI_SX},
                            component: () => import("@/view/pages/course/CourseIndex")
                        },
                        {
                            path: "edit/:id",
                            name: "course-edit",
                            meta: {authorize: PERMISSION.QUAN_LY_DON_VI_SX},
                            component: () => import("@/view/pages/course/CourseEdit")
                        },
                    ],
                },
                {
                    path: "/news",
                    name: "news",
                    component: () => import("@/view/pages/auth/users/User"),
                    children: [
                        {
                            path: "create",
                            name: "news-create",
                            meta: {authorize: PERMISSION.DANH_SACH_TIN_TUC},
                            component: () => import("@/view/pages/news/NewsCreate")
                        },
                        {
                            path: "/",
                            name: "news-index",
                            meta: {authorize: PERMISSION.DANH_SACH_TIN_TUC},
                            component: () => import("@/view/pages/news/NewsIndex")
                        },
                        {
                            path: "edit/:id",
                            name: "news-edit",
                            meta: {authorize: PERMISSION.DANH_SACH_TIN_TUC},
                            component: () => import("@/view/pages/news/NewsEdit")
                        },
                    ],
                },
                {
                    path: "/event-web",
                    name: "event-web",
                    component: () => import("@/view/pages/auth/users/User"),
                    children: [
                        {
                            path: "create",
                            name: "event-web-create",
                            meta: {authorize: PERMISSION.DANH_SACH_SU_KIEN},
                            component: () => import("@/view/pages/event-web/EventsCreate")
                        },
                        {
                            path: "/",
                            name: "event-web-index",
                            meta: {authorize: PERMISSION.DANH_SACH_SU_KIEN},
                            component: () => import("@/view/pages/event-web/EventsIndex")
                        },
                        {
                            path: "edit/:id",
                            name: "event-web-edit",
                            meta: {authorize: PERMISSION.DANH_SACH_SU_KIEN},
                            component: () => import("@/view/pages/event-web/EventsEdit")
                        },
                    ],
                },
                {
                    path: "/prize",
                    name: "prize",
                    component: () => import("@/view/pages/prize-draw/EdutalkPrizeDraw"),
                    children: [
                        {
                            name: "prize-draw",
                            path: "/",
                            meta: {authorize: PERMISSION.CHUONG_TRINH_HOAN_TIEN},
                            component: () =>
                                import("@/view/pages/rewards-program/PrizeDrawIndex"),
                        },
                    ],
                },
                {
                    path: "/pages",
                    name: "pages",
                    component: () => import("@/view/pages/auth/users/User"),
                    children: [
                        {
                            path: "create",
                            name: "page-create",
                            meta: {authorize: PERMISSION.QUAN_LY_KENH_WEBSITE},
                            component: () => import("@/view/pages/pages/PageCreate")
                        },
                        {
                            path: "/",
                            name: "page-index",
                            meta: {authorize: PERMISSION.QUAN_LY_KENH_WEBSITE},
                            component: () => import("@/view/pages/pages/PageIndex")
                        },
                        {
                            path: "edit/:id",
                            name: "page-edit",
                            meta: {authorize: PERMISSION.QUAN_LY_KENH_WEBSITE},
                            component: () => import("@/view/pages/pages/PageEdit")
                        },
                    ],
                },
                {
                    path: "/templates",
                    name: "templates",
                    component: () => import("@/view/pages/auth/users/User"),
                    children: [
                        {
                            path: "/",
                            name: "template-index",
                            meta: {authorize: []},
                            component: () => import("@/view/pages/templates/TemplateIndex")
                        },
                    ],
                },
                {
                    path: "/promotions",
                    name: "promotions",
                    component: () => import("@/view/pages/auth/users/User"),
                    children: [
                        {
                            path: "create",
                            name: "promotion-create",
                            meta: {authorize: PERMISSION.QUAN_LY_KENH_WEBSITE},
                            component: () => import("@/view/pages/promotions/PromotionCreate")
                        },
                        {
                            path: "/",
                            name: "promotion-index",
                            meta: {authorize: PERMISSION.QUAN_LY_KENH_WEBSITE},
                            component: () => import("@/view/pages/promotions/PromotionIndex")
                        },
                        {
                            path: "edit/:id",
                            name: "promotion-edit",
                            meta: {authorize: PERMISSION.QUAN_LY_KENH_WEBSITE},
                            component: () => import("@/view/pages/promotions/PromotionEdit")
                        },
                    ],
                },
                {
                    path: "/promotions",
                    name: "promotions",
                    component: () => import("@/view/pages/auth/users/User"),
                    children: [
                        {
                            path: "create",
                            name: "promotion-create",
                            meta: {authorize: PERMISSION.QUAN_LY_KENH_WEBSITE},
                            component: () => import("@/view/pages/promotions/PromotionCreate")
                        },
                        {
                            path: "/",
                            name: "promotion-index",
                            meta: {authorize: PERMISSION.QUAN_LY_KENH_WEBSITE},
                            component: () => import("@/view/pages/promotions/PromotionIndex")
                        },
                        {
                            path: "edit/:id",
                            name: "promotion-edit",
                            meta: {authorize: PERMISSION.QUAN_LY_KENH_WEBSITE},
                            component: () => import("@/view/pages/promotions/PromotionEdit")
                        },
                    ],
                },
                {
                    path: "/schedule-runing",
                    name: "scheduleRuning",
                    component: () => import("@/view/pages/auth/users/User"),
                    children: [
                        {
                            path: "/",
                            name: "schedule-runing",
                            meta: {authorize: PERMISSION.QUAN_LY_KENH_WEBSITE},
                            component: () => import("@/view/pages/schedule-runing/ScheduleRuning")
                        },

                    ],
                },
                {
                    path: "/production-management",
                    name: "ProductionUnitManagement",
                    component: () => import("@/view/pages/production-management/ProductionManagement.vue"),
                    children: [
                        {
                            path: "",
                            name: "ProductionManagement",
                            meta: {authorize: PERMISSION.NGUON_LUC_MO_LOP},
                            component: () => import("@/view/pages/production-management/ProductionManagementList.vue")
                        },
                        {
                            path: "/management-group-teacher",
                            name: "ManageGroupTeacher",
                            meta: {authorize: 'ALL'},
                            component: () => import("@/view/pages/production-management/ManageGroupTeacher.vue")
                        },
                    ],
                },
                {
                    path: "/information",
                    name: "system-information",
                    component: () => import("@/view/pages/auth/users/User"),
                    children: [
                        {
                            path: "create",
                            name: "information-create",
                            meta: {authorize: PERMISSION.QUAN_LY_HE_THONG_THONG_TIN},
                            component: () => import("@/view/pages/system-information/InformationCreate")
                        },
                        {
                            path: "/",
                            name: "information-index",
                            meta: {authorize: PERMISSION.QUAN_LY_HE_THONG_THONG_TIN},
                            component: () => import("@/view/pages/system-information/InformationIndex")
                        },
                        {
                            path: "edit/:id",
                            name: "information-edit",
                            meta: {authorize: PERMISSION.QUAN_LY_HE_THONG_THONG_TIN},
                            component: () => import("@/view/pages/system-information/InformationEdit")
                        },
                        {
                            path: "/notification",
                            name: "notification",
                            meta: {authorize: PERMISSION.QUAN_LY_HE_THONG_THONG_TIN},
                            component: () => import("@/view/pages/system-information/notification-ticket-analyst.vue"),
                            children: [
                                {
                                    path: "",
                                    name: "NotificationList",
                                    meta: {authorize: PERMISSION.QUAN_LY_NOTIFICATION},
                                    component: () => import("@/view/pages/system-information/NotificationTicketList.vue")
                                },
                            ]
                        },
                    ],
                },
                {
                    path: "/handbook",
                    name: "system-system-handbook",
                    component: () => import("@/view/pages/auth/users/User"),
                    children: [
                        {
                            path: "create",
                            name: "handbook-create",
                            meta: {authorize: PERMISSION.QUAN_LY_HE_THONG_THONG_TIN},
                            component: () => import("@/view/pages/system-handbook/HandbookCreate")
                        },
                        {
                            path: "/",
                            name: "handbook-index",
                            meta: {authorize: PERMISSION.QUAN_LY_HE_THONG_THONG_TIN},
                            component: () => import("@/view/pages/system-handbook/HandbookIndex")
                        },
                        {
                            path: "edit/:id",
                            name: "handbook-edit",
                            meta: {authorize: PERMISSION.QUAN_LY_HE_THONG_THONG_TIN},
                            component: () => import("@/view/pages/system-handbook/HandbookEdit")
                        },
                    ],
                },
                {
                    path: "/mechanism-headquarters",
                    name: "system-mechanism",
                    component: () => import("@/view/pages/auth/users/User"),
                    children: [
                        {
                            path: "create",
                            name: "mechanism-create",
                            meta: {authorize: PERMISSION.QUAN_LY_HE_THONG_THONG_TIN},
                            component: () => import("@/view/pages/system-mechanism/MechanismCreate")
                        },
                        {
                            path: "/",
                            name: "mechanism-index",
                            meta: {authorize: PERMISSION.QUAN_LY_HE_THONG_THONG_TIN},
                            component: () => import("@/view/pages/system-mechanism/MechanismIndex")
                        },
                        {
                            path: "edit/:id",
                            name: "mechanism-edit",
                            meta: {authorize: PERMISSION.QUAN_LY_HE_THONG_THONG_TIN},
                            component: () => import("@/view/pages/system-mechanism/MechanismEdit")
                        },
                    ],
                },
                {
                    path: "/term-policy",
                    name: "term-policy",
                    component: () => import("@/view/pages/auth/users/User"),
                    children: [
                        {
                            path: "create",
                            name: "term-policy-create",
                            meta: {authorize: PERMISSION.QUAN_LY_DIEU_KHOAN_CHINH_SACH},
                            component: () => import("@/view/pages/term-policy/TermPolicyCreate")
                        },
                        {
                            path: "/",
                            name: "term-policy",
                            meta: {authorize: PERMISSION.QUAN_LY_DIEU_KHOAN_CHINH_SACH},
                            component: () => import("@/view/pages/term-policy/TermPolicyIndex")
                        },
                        {
                            path: "edit/:id",
                            name: "term-policy-edit",
                            meta: {authorize: PERMISSION.QUAN_LY_DIEU_KHOAN_CHINH_SACH},
                            component: () => import("@/view/pages/term-policy/TermPolicyEdit")
                        },
                        {
                            path: "history/:id",
                            name: "term-policy-history",
                            meta: {authorize: PERMISSION.QUAN_LY_DIEU_KHOAN_CHINH_SACH},
                            component: () => import("@/view/pages/term-policy/HistoryUpdate")
                        },
                    ],
                },
                {
                    path: "/policy-manage",
                    name: "policy-manage",
                    component: () => import("@/view/pages/auth/users/User"),
                    children: [
                        {
                            path: "create",
                            name: "term-policy-create",
                            meta: {authorize: PERMISSION.QUAN_LY_DAO_TAO},
                            component: () => import("@/view/pages/term-policy/TermPolicyCreate")
                        },
                        {
                            path: "/",
                            name: "policy-manage",
                            meta: {authorize: PERMISSION.QUAN_LY_DAO_TAO},
                            component: () => import("@/view/pages/manage-course/ManageCourseIndex")
                        },
                        // {
                        //     path: "edit/:id",
                        //     name: "term-policy-edit",
                        //     meta: {authorize: PERMISSION.QUAN_LY_DIEU_KHOAN_CHINH_SACH},
                        //     component: () => import("@/view/pages/term-policy/TermPolicyEdit")
                        // },
                        // {
                        //     path: "history/:id",
                        //     name: "term-policy-history",
                        //     meta: {authorize: PERMISSION.QUAN_LY_DIEU_KHOAN_CHINH_SACH},
                        //     component: () => import("@/view/pages/term-policy/HistoryUpdate")
                        // },
                    ],
                },

                {
                    path: "/manage-candidate-teacher",
                    name: "manage-candidate-teacher",
                    component: () => import("@/view/pages/auth/users/User"),
                    children: [
                        {
                            path: "/manage-candidate-teacher",
                            name: "manage-candidate-teacher",
                            meta: {authorize: PERMISSION.QUAN_LY_DAO_TAO_UNG_VIEN_GIANG_VIEN},
                            component: () => import("@/view/pages/user/ManageCandidateTeacher.vue")

                        },
                    ],
                },
                {
                    path: "/trainning-report",
                    name: "trainning-report",
                    component: () => import("@/view/pages/auth/users/User"),
                    children: [
                        {
                            path: "/trainning-report",
                            name: "trainning-report",
                            meta: {authorize: PERMISSION.BAO_CAO_DAO_TAO},
                            component: () => import("@/view/pages/user/ReportTraining.vue")

                        },
                    ],
                },
                {
                    path: "/catalog-policy",
                    name: "catalog-policy",
                    component: () => import("@/view/pages/auth/users/User"),
                    children: [
                        {
                            path: "/",
                            name: "catalog-policy",
                            meta: {authorize: PERMISSION.QUAN_LY_HE_THONG_THONG_TIN},
                            component: () => import("@/view/pages/catalog-policy/CatalogPolicyIndex")
                        },
                    ],
                },
                {
                    path: "/mechanism-basis",
                    name: "system-mechanism",
                    component: () => import("@/view/pages/auth/users/User"),
                    children: [
                        {
                            path: "create",
                            name: "mechanism-basis-create",
                            meta: {authorize: PERMISSION.QUAN_LY_HE_THONG_THONG_TIN},
                            component: () => import("@/view/pages/system-mechanism-basis/MechanismBasisCreate")
                        },
                        {
                            path: "/",
                            name: "mechanism-basis-index",
                            meta: {authorize: PERMISSION.QUAN_LY_HE_THONG_THONG_TIN},
                            component: () => import("@/view/pages/system-mechanism-basis/MechanismBasisIndex")
                        },
                        {
                            path: "edit/:id",
                            name: "mechanism-basis-edit",
                            meta: {authorize: PERMISSION.QUAN_LY_HE_THONG_THONG_TIN},
                            component: () => import("@/view/pages/system-mechanism-basis/MechanismBasisEdit")
                        },
                    ],
                },
                {
                    path: "/classes",
                    name: "classes",
                    component: () => import("@/view/pages/auth/users/User"),
                    children: [
                        {
                            path: "create",
                            name: "class-create",
                            meta: {authorize: PERMISSION.QUAN_LY_DON_VI_SX},
                            component: () => import("@/view/pages/classes/ClassCreate")
                        },
                        {
                            path: "/",
                            name: "class-index",
                            meta: {authorize: PERMISSION.QUAN_LY_DON_VI_SX},
                            component: () => import("@/view/pages/classes/ClassIndex")
                        },
                        {
                            path: "edit/:id",
                            name: "class-edit",
                            meta: {authorize: PERMISSION.QUAN_LY_DON_VI_SX},
                            component: () => import("@/view/pages/classes/ClassEdit")
                        },
                        {
                            path: "detail/:id",
                            name: "class-detail",
                            meta: {authorize: PERMISSION.QUAN_LY_DON_VI_SX},
                            component: () => import("@/view/pages/classes/ClassDetail")
                        },
                        {
                            path: "mark-homework/:id",
                            name: "mark-homework",
                            meta: {authorize: PERMISSION.SUA_LOI_DAY_HOC},
                            component: () => import("@/view/pages/classes/MarkHomework.vue")
                        },
                        {
                            path: "content-homework/:id",
                            name: "content-homework",
                            meta: {authorize: PERMISSION.SUA_LOI_DAY_HOC},
                            component: () => import("@/view/pages/classes/ContentHomeWork")
                        },
                        {
                            path: "manage-error",
                            name: "manage-error",
                            meta: {authorize: PERMISSION.SUA_LOI_DAY_HOC},
                            component: () => import("@/view/pages/classes/ClassErrorList")
                        },
                        {
                            path: "schedule-class/:id",
                            name: "schedule-class",
                            meta: {authorize: PERMISSION.SUA_LOI_DAY_HOC},
                            component: () => import("@/view/pages/classes/ScheduleClass"),
                        },
                        {
                            path: "schedule-class/comment/:id",
                            name: "schedule-class-comment",
                            meta: {authorize: PERMISSION.SUA_LOI_DAY_HOC},
                            component: () => import("@/view/pages/classes/FeedbackHomework.vue"),
                        },
                        {
                            path: "schedule-class/list_mark_homework/:id",
                            name: "list_mark_homework",
                            meta: {authorize: PERMISSION.SUA_LOI_DAY_HOC},
                            component: () => import("@/view/pages/classes/ListHomeworkStudent.vue"),
                        },
                        {
                            path: "upload-data-class/:id",
                            name: "upload-data-class",
                            meta: {authorize: PERMISSION.SUA_LOI_DAY_HOC},
                            component: () => import("@/view/pages/classes/uploadDataClass")
                        },
                        {
                            path: "/slide/:id/classroom/:classroom_id",
                            name: "content-slide",
                            meta: {authorize: PERMISSION.SUA_LOI_DAY_HOC},
                            component: () => import("@/view/pages/classes/SlideContent"),
                        },
                        {
                            path: "team-class/:id",
                            name: "team-class",
                            meta: {authorize: PERMISSION.SUA_LOI_DAY_HOC},
                            component: () => import("@/view/pages/classes/team/Team"),
                        },
                    ],
                },
                {
                    path: "/room",
                    name: "room",
                    component: () => import("@/view/pages/auth/users/User"),
                    children: [
                        {
                            path: "create",
                            name: "room-create",
                            meta: {authorize: PERMISSION.QUAN_LY_DON_VI_SX},
                            component: () => import("@/view/pages/rooms/RoomCreate")
                        },
                        {
                            path: "/",
                            name: "room-index",
                            meta: {authorize: PERMISSION.QUAN_LY_DON_VI_SX},
                            component: () => import("@/view/pages/rooms/RoomIndex")
                        },
                        {
                            path: "edit/:id",
                            name: "room-edit",
                            meta: {authorize: PERMISSION.QUAN_LY_DON_VI_SX},
                            component: () => import("@/view/pages/rooms/RoomEdit")
                        },
                    ],
                },
                {
                    path: "/room-empty",
                    name: "room-empty",
                    meta: {authorize: PERMISSION.QUAN_LY_DON_VI_SX},
                    component: () => import("@/view/pages/rooms/RoomEmpty")
                },
                {
                    path: "/centers",
                    name: "centers",
                    component: () => import("@/view/pages/centers/Center"),
                    children: [
                        {
                            name: "center-list",
                            path: "/",
                            meta: {authorize: PERMISSION.QUAN_LY_KENH_WEBSITE},
                            component: () =>
                                import("@/view/pages/centers/CenterIndex"),
                        },
                        {
                            path: "educenter",
                            name: "center-educenter",
                            meta: {authorize: PERMISSION.QUAN_LY_DON_VI_SX},
                            component: () => import("@/view/pages/centers/List-Center")
                        },
                        {
                            name: "center-create",
                            path: "create",
                            meta: {authorize: PERMISSION.QUAN_LY_DON_VI_SX},
                            component: () =>
                                import("@/view/pages/centers/Create-Center"),
                        },
                        {
                            name: "center-update",
                            path: "update/:id",
                            meta: {authorize: PERMISSION.QUAN_LY_DON_VI_SX},
                            component: () =>
                                import("@/view/pages/centers/Edit-Center"),
                        },
                        {
                            name: "center-old-update",
                            path: "update/educenter/:id",
                            meta: {authorize: PERMISSION.QUAN_LY_DON_VI_SX},
                            component: () =>
                                import("@/view/pages/centers/Edit-Educenter-Center"),
                        },
                    ],
                },
                {
                    path: "/teachers",
                    name: "teachers",
                    component: () => import("@/view/pages/teachers/Teacher"),
                    children: [
                        {
                            name: "teacher-list",
                            path: "/",
                            meta: {authorize: PERMISSION.QUAN_LY_DON_VI_SX},
                            component: () =>
                                import("@/view/pages/teachers/List-Teacher"),
                        },
                        {
                            name: "teacher-create",
                            path: "create",
                            meta: {authorize: PERMISSION.QUAN_LY_DON_VI_SX},
                            component: () =>
                                import("@/view/pages/teachers/Create-Teacher"),
                        },
                        {
                            name: "teacher-update",
                            path: "update/:id",
                            meta: {authorize: PERMISSION.QUAN_LY_DON_VI_SX},
                            component: () =>
                                import("@/view/pages/teachers/Edit-Teacher"),
                        },
                        {
                            name: "teacher-detail",
                            path: "view/:id",
                            meta: {authorize: PERMISSION.QUAN_LY_DON_VI_SX},
                            component: () =>
                                import("@/view/pages/teachers/View-Teacher"),
                        },
                    ],
                },
                {
                    path: "/reviews",
                    name: "reviews",
                    component: () => import("@/view/pages/reviews/Review"),
                    children: [
                        {
                            name: "review-list",
                            path: "/",
                            meta: {authorize: PERMISSION.QUAN_LY_DON_VI_SX},
                            component: () =>
                                import("@/view/pages/reviews/List-Review"),
                        },
                        {
                            name: "review-create",
                            path: "create",
                            meta: {authorize: PERMISSION.QUAN_LY_DON_VI_SX},
                            component: () =>
                                import("@/view/pages/reviews/Create-Review"),
                        },
                        {
                            name: "review-update",
                            path: "update/:id",
                            meta: {authorize: PERMISSION.QUAN_LY_DON_VI_SX},
                            component: () =>
                                import("@/view/pages/reviews/Edit-Review"),
                        },
                    ],
                },
                {
                    path: "/payment",
                    name: "payment",
                    component: () => import("@/view/pages/business/EdutalkBusiness"),
                    children: [
                        {
                            name: "customer-payments",
                            path: "list",
                            meta: {authorize: PERMISSION.NAP_RUT_TANG_QUA},
                            component: () =>
                                import("@/view/pages/business/NapRutTangQua"),
                        },
                        {
                            name: "nap-rut-19-7",
                            path: "nap-rut-19-7",
                            meta: {authorize: PERMISSION.NAP_RUT_197},
                            component: () =>
                                import("@/view/pages/contracts/NapRut197"),
                        },
                        {
                            name: "tieu-so-du",
                            path: "tieu-so-du",
                            meta: {authorize: PERMISSION.XU_LY_SO_DU_TAI_KHOAN},
                            component: () =>
                                import("@/view/pages/payments/TieuSoDu"),
                        },
                        {
                            name: "payment-log",
                            path: "log",
                            meta: {authorize: PERMISSION.DS_KH_DONG_TIEN},
                            component: () =>
                                import("@/view/pages/payments/Log"),
                        },
                        {
                            name: "amount-log-list", //Danh sách đóng tiền ở khối cơ sở
                            path: "amount-logs",
                            meta: {authorize: PERMISSION.XEM_DANH_SACH_DONG_TIEN},
                            component: () =>
                                import("@/view/pages/payments/AmountLogs"),
                        },
                        {
                            name: "tai-chinh-theo-khoa-hoc",
                            path: "course-student",
                            meta: {authorize: PERMISSION.KHOA_HOC_DANG_KY},
                            component: () =>
                                import("@/view/pages/business/danhSachKhoaHocTrenWebsite"),
                        },
                        {
                            name: "huy-tieu-khoa-hoc",
                            path: "huy-tieu-khoa-hoc",
                            meta: {authorize: PERMISSION.HUY_TIEU_KHOA_HOC},
                            component: () =>
                                import("@/view/pages/payments/TieuKhoaHoc"),
                        },
                        {
                            name: "manage-department-list",
                            path: "manage-department-list",
                            meta: {authorize: PERMISSION.XEM_DANH_SACH_NHAN_SU_CO_SO},
                            component: () =>
                                import("@/view/pages/department/ManageDepartmentList"),
                            children: [
                                {
                                    name: "list-department",
                                    path: "/",
                                    meta: {authorize: PERMISSION.XEM_DANH_SACH_NHAN_SU_CO_SO},
                                    component: () =>
                                        import("@/view/pages/department/ListDepartment"),
                                },
                                {
                                    name: "department-create",
                                    path: "create",
                                    meta: {authorize: PERMISSION.XEM_DANH_SACH_NHAN_SU_CO_SO},
                                    component: () =>
                                        import("@/view/pages/department/Create-department"),
                                },
                                // {
                                //     name: "permission-update",
                                //     path: "update/:id",
                                //     meta: {authorize: PERMISSION.QUAN_LY_TAI_KHOAN},
                                //     component: () =>
                                //         import("@/view/pages/auth/permissions/Edit-permission"),
                                // },
                            ],
                        },
                    ],
                },

                {
                    path: "/finance-transaction",
                    name: "finance-transaction-list",
                    component: () => import("@/view/pages/finance/FinanceTransactions"),
                    children: [
                        {
                            name: "list-finance-transaction",
                            path: "/",
                            meta: {authorize: PERMISSION.QUAN_LY_VON_GOP_THUC_TE},
                            component: () =>
                                import("@/view/pages/finance/FinanceTransactionIndex"),
                        },
                        {
                            name: "finance-actual-contributeds",
                            path: "actual-contributeds",
                            meta: {authorize: PERMISSION.CHI_VAN_PHONG},
                            component: () =>
                                import("@/view/pages/finance/FinanceActualContributeds"),
                        },
                    ],
                },

                {
                    path: "/course-gift",
                    name: "course-gift",
                    component: () => import("@/view/pages/finance/FinanceTransactions"),
                    children: [
                        {
                            name: "list-course-gift",
                            path: "/",
                            meta: {authorize: PERMISSION.QUAN_LY_QUA_TANG},
                            component: () =>
                                import("@/view/pages/course-gift/CourseGiftIndex"),
                        },
                        {
                            name: "export-course-gift",
                            path: "export",
                            meta: {authorize: PERMISSION.BAO_CAO_XUAT_NHAP_KHO},
                            component: () =>
                                import("@/view/pages/course-gift/ExportCourseGift"),
                        },

                    ],
                },
                //hoàn tiền
                {
                    path: "/refund-program",
                    name: "refund-program",
                    component: () => import("@/view/pages/refund/EdutalkRefundProgram"),
                    children: [
                        {
                            name: "refund-list",
                            path: "/",
                            meta: {authorize: PERMISSION.QUAN_LY_DON},
                            component: () =>
                                import("@/view/pages/refund/RefundList"),
                        },
                        {
                            name: "refund-list",
                            path: "role-department",
                            meta: {authorize: PERMISSION.CHUONG_TRINH_HOAN_TIEN},
                            component: () =>
                                import("@/view/pages/refund/RoleDepartment"),
                        },
                    ],
                },
                {
                    path: "/role-department",
                    name: "role-department",
                    component: () => import("@/view/pages/refund/EdutalkRefundProgram"),
                    children: [
                        {
                            name: "list-role-department",
                            path: "/",
                            meta: {authorize: PERMISSION.QUAN_LY_DON_VI_TRU_SO_TONG},
                            component: () =>
                                import("@/view/pages/refund/RoleDepartment"),
                        },
                    ],
                },
                {
                    path: "/contracts",
                    name: "contract",
                    component: () => import("@/view/pages/auth/users/User"),
                    children: [
                        {
                            name: "move-amount",
                            path: "student-advance",
                            meta: {authorize: PERMISSION.HOP_DONG_UNG},
                            component: () =>
                                import("@/view/pages/contracts/StudentAmount"),
                        },
                        {
                            name: "cost-price",
                            path: "cost-price",
                            meta: {authorize: PERMISSION.GIA_VON},
                            component: () =>
                                import("@/view/pages/contracts/CostPrice"),
                        },
                        {
                            name: "edutalk-payment",
                            path: "edutalk-payment",
                            meta: {authorize: PERMISSION.EDUTALK_THU_TIEN},
                            component: () =>
                                import("@/view/pages/contracts/EdutalkPayment"),
                        },
                        // {
                        //     name: "bill",
                        //     path: "bill",
                        //     meta: {authorize: PERMISSION.EDUTALK_THU_TIEN},
                        //     component: () =>
                        //         import("@/view/pages/contracts/Bill"),
                        // },
                        {
                            name: "contract-index",
                            path: "/",
                            meta: {authorize: PERMISSION.XEM_DS_HD},
                            component: () =>
                                import("@/view/pages/contracts/ContractIndex"),
                        },
                        {
                            name: "contract-create",
                            path: "create",
                            meta: {authorize: PERMISSION.TAO_HD},
                            component: () =>
                                import("@/view/pages/contracts/ContractCreate"),
                        },
                    ],
                },
                {
                    path: "/human-resources",
                    name: "human-resources",
                    component: () => import("@/view/pages/auth/log_resources/LogResource"),
                    children: [
                        {
                            name: "log-resource-list",
                            path: "/",
                            meta: {authorize: PERMISSION.PHIEU_LUONG},
                            component: () =>
                                import("@/view/pages/auth/log_resources/LogResourceIndex"),
                        },
                    ],
                },
                // {
                //     path: "/pay-slip-teacher",
                //     name: "pay-slip-teacher",
                //     component: () => import("@/view/pages/auth/pay-slip-teacher/PaySlipTeacher"),
                //     children: [
                //         {
                //             name: "pay-slip-teacher",
                //             path: "/",
                //             meta: {authorize: PERMISSION.PHIEU_LUONG},
                //             component: () =>
                //                 import("@/view/pages/auth/pay-slip-teacher/PaySlipTeacherIndex"),
                //         },
                //     ],
                // },
                {
                    path: "/lucky_draw",
                    name: "lucky_draw",
                    component: () => import("@/view/pages/auth/pay-slip-teacher/PaySlipTeacher"),
                    children: [
                        {
                            name: "lucky_draw",
                            path: "/",
                            meta: {authorize: PERMISSION.PHIEU_LUONG},
                            component: () =>
                                import("@/view/pages/auth/pay-slip-teacher/LuckyDraw"),
                        },
                    ],
                },
                {
                    path: "/course-type",
                    name: "course-type",
                    component: () => import("@/view/pages/course-type/Course-Type"),
                    children: [
                        {
                            name: "course-type-list",
                            path: "/",
                            meta: {authorize: PERMISSION.QUAN_LY_DON_VI_SX},
                            component: () =>
                                import("@/view/pages/course-type/Course-Type-List"),
                        },
                    ],
                },
                {
                    path: "/course-level",
                    name: "course-level",
                    component: () => import("@/view/pages/course-level/Course-Level"),
                    children: [
                        {
                            name: "course-level-list",
                            path: "/",
                            meta: {authorize: PERMISSION.QUAN_LY_DON_VI_SX},
                            component: () =>
                                import("@/view/pages/course-level/Course-Level-List"),
                        },
                    ],
                },
                {
                    path: "/sale",
                    name: "sale",
                    component: () => import("@/view/pages/course-level/Course-Level"),
                    children: [
                        {
                            name: "sale-tree-view",
                            path: "tree-view",
                            meta: {authorize: PERMISSION.XEM_DANH_SACH_NHAN_SU_CO_SO},
                            component: () => import("@/view/pages/sale/SaleTreeView"),
                        },
                        {
                            name: "move-branch",
                            path: "move",
                            meta: {authorize: PERMISSION.DIEU_CHUYEN_NHAN_SU},
                            component: () => import("@/view/pages/sale/MoveBranch"),
                        },
                        {
                            name: "sale-promotion",
                            path: "promotion",
                            meta: {authorize: PERMISSION.SALE_LEN_CAP},
                            component: () =>
                                import("@/view/pages/sale/Level-Up"),
                        },
                        {
                            name: "sale-degradation",
                            path: "degradation",
                            meta: {authorize: PERMISSION.SALE_XUONG_CAP},
                            component: () =>
                                import("@/view/pages/sale/Level-Down"),
                        },
                    ],
                },
                {
                    path: "/reports",
                    name: "reports",
                    component: () => import("@/view/pages/report/Student-Amount"),
                    children: [
                        {
                            name: "edutalk-center-payment",
                            path: "student-amounts",
                            meta: {authorize: PERMISSION.QUYET_TOAN_TRUNG_TAM},
                            component: () =>
                                import("@/view/pages/report/Student-Amount-Payment"),
                        },
                        {
                            name: "dskh-doi",
                            path: "dskh-doi",
                            meta: {authorize: PERMISSION.BAO_CAO_DOANH_THU_DOI},
                            component: () =>
                                import("@/view/pages/report/DSKH-Team-V2"),
                        },
                        {
                            name: "dskh-branch",
                            path: "dskh-branch",
                            meta: {authorize: PERMISSION.BAO_CAO_DOANH_THU_TAI_DIEM},
                            component: () =>
                                import("@/view/pages/report/DSKHBranch"),
                        },
                        {
                            name: "report-digital",
                            path: "digital",
                            meta: {authorize: PERMISSION.BAO_CAO_DIGITAL},
                            component: () =>
                                import("@/view/pages/report/ReportDigital"),
                        },
                        {
                            name: "report-tvv",
                            path: "tvv",
                            meta: {authorize: PERMISSION.BAO_CAO_TVV},
                            component: () =>
                                import("@/view/pages/report/ReportTvv"),
                        },
                        {
                            name: "sale-time-keep",
                            path: "sale-time-keep",
                            meta: {authorize: PERMISSION.BAO_CAO},
                            component: () =>
                                import("@/view/pages/report/TimeKeep"),
                        },
                        {
                            name: "employee",
                            path: "employee",
                            meta: {authorize: PERMISSION.BAO_CAO},
                            component: () =>
                                import("@/view/pages/report/Employee"),
                        },
                        {
                            name: "cong-no-khach-hang",
                            path: "cong-no",
                            meta: {authorize: PERMISSION.CONG_NO_KHACH_HANG},
                            component: () =>
                                import("@/view/pages/business/report/CongNoKhachHang"),
                        },
                        {
                            path: "/book-costs",
                            name: "book-costs",
                            meta: {authorize: PERMISSION.QUAN_LY_CHI_PHI_SACH},
                            component: () => import("@/view/pages/payments/book-costs/BookCosts.vue"),
                            children: [
                                {
                                    name: "book-costs-list",
                                    path: "/",
                                    meta: {authorize: PERMISSION.QUAN_LY_CHI_PHI_SACH},
                                    component: () =>
                                        import("@/view/pages/payments/book-costs/BookCostsList.vue"),
                                },
                            ],
                        },
                        {
                            path: "/box-costs",
                            name: "box-costs",
                            meta: {authorize: PERMISSION.QUAN_LY_CHI_PHI_BOX},
                            component: () => import("@/view/pages/payments/box-costs/BoxCosts.vue"),
                            children: [
                                {
                                    name: "box-costs-list",
                                    path: "/",
                                    meta: {authorize: PERMISSION.QUAN_LY_CHI_PHI_BOX},
                                    component: () =>
                                        import("@/view/pages/payments/box-costs/BoxCostsList.vue"),
                                },
                            ],
                        },

                        {
                            name: "NetRevenueExport",
                            path: "net-revenue",
                            meta: {authorize: PERMISSION.QUAN_LY_DOANH_THU_THUAN},
                            component: () =>
                                import("@/view/pages/payments/net-revenue/NetRevenueExport.vue"),
                        },
                        {
                            name: "student-all",
                            path: "students/all",
                            meta: {authorize: PERMISSION.QUAN_LY_DON_VI_SX},
                            component: () =>
                                import("@/view/pages/report/StudentAll"),
                        },
                        {
                            name: "student-all-show",
                            path: "students-all/show/:id",
                            meta: {authorize: 'ALL'},
                            component: () =>
                                import("@/view/pages/report/StudentAllShow"),
                        },
                    ],
                },
                {
                    path: "/business-coefficient",
                    name: "business-coefficient",
                    component: () => import("@/view/pages/business-coefficient/BusinessCoefficient"),
                    children: [
                        {
                            name: "business-coefficient-index",
                            path: "/",
                            meta: {authorize: PERMISSION.QUAN_LY_HE_SO_KINH_DOANH},
                            component: () =>
                                import("@/view/pages/business-coefficient/BusinessCoefficientIndex"),
                        },
                        {
                            name: "business-coefficient-create",
                            path: "/business-coefficient/create",
                            meta: {authorize: PERMISSION.QUAN_LY_HE_SO_KINH_DOANH},
                            component: () =>
                                import("@/view/pages/business-coefficient/BusinessCoefficientCreate"),
                        },
                        {
                            name: "business-coefficient-update",
                            path: "/business-coefficient/update/:id",
                            meta: {authorize: PERMISSION.QUAN_LY_HE_SO_KINH_DOANH},
                            component: () =>
                                import("@/view/pages/business-coefficient/BusinessCoefficientUpdate"),
                        },
                    ],
                },
                {
                    path: "/recruitment",
                    name: "recruitment",
                    component: () => import("@/view/pages/recruitment/Recruitment"),
                    children: [
                        {
                            name: "jobs-list",
                            path: "jobs",
                            meta: {authorize: PERMISSION.WEBSITE_TUYEN_DUNG},
                            component: () =>
                                import("@/view/pages/recruitment/RecruitmentJobsList"),
                        },
                        {
                            name: "jobs-update",
                            path: "jobs/:id",
                            meta: {authorize: PERMISSION.WEBSITE_TUYEN_DUNG},
                            component: () =>
                                import("@/view/pages/recruitment/RecruitmentJobUpdate"),
                        },
                        {
                            name: "jobs-create",
                            path: "jobs-create",
                            meta: {authorize: PERMISSION.WEBSITE_TUYEN_DUNG},
                            component: () =>
                                import("@/view/pages/recruitment/RecruitmentJobCreate"),
                        },
                        {
                            name: "curriculum-vitae-list-by-job",
                            path: "cv-by-job/:jobId",
                            meta: {authorize: PERMISSION.WEBSITE_TUYEN_DUNG},
                            component: () =>
                                import("@/view/pages/recruitment/CurriculumVitaeList"),
                        },
                    ],
                },
                {
                    path: "/customer-user",
                    name: "",
                    component: () => import("@/view/pages/auth/users/User"),
                    children: [
                        {
                            path: "/",
                            name: "customer-user",
                            meta: {authorize: PERMISSION.XEM_DS_KH},
                            component: () =>
                                import("@/view/pages/customers/CustomerIndex"),
                        },
                        {
                            path: "edit/:id",
                            name: "customer-user-edit",
                            meta: {authorize: PERMISSION.CHI_TIET_DSKH},
                            component: () => import("@/view/pages/customers/CustomerEdit")
                        },
                        {
                            path: "update/:id",
                            name: "customer-user-update",
                            meta: {authorize: PERMISSION.SUA_KHACH_HANG},
                            component: () => import("@/view/pages/customers/CustomerUpdate")
                        },
                        {
                            path: "history/:id",
                            name: "customer-user-history",
                            meta: {authorize: PERMISSION.XEM_LICH_SU},
                            component: () => import("@/view/pages/customers/CustomerHistory")
                        },
                    ],
                },
                {
                    path: "/customer-crm",
                    name: "",
                    component: () => import("@/view/pages/customers/CustomerCrmIndex"),
                    children: [
                        {
                            path: "/",
                            name: "customer-crm",
                            meta: {authorize: PERMISSION.XEM_QLKH},
                            component: () =>
                                import("@/view/pages/customers/CustomerCrm"),
                        },
                        {
                            path: "detail/:id",
                            name: "customer-crm-detail",
                            meta: {authorize: PERMISSION.XEM_QLKH},
                            component: () => import("@/view/pages/customers/DetailCustomerCrm")
                        },
                        {
                            path: "chi-tiet/:id",
                            name: "detail-customer",
                            meta: {authorize: PERMISSION.XEM_QLKH},
                            component: () => import("@/view/pages/customers/CustomerDetail")
                        },
                        {
                            path: "history/:id",
                            name: "history-customer",
                            meta: {authorize: PERMISSION.XEM_QLKH},
                            component: () => import("@/view/pages/customers/CrmHistory")
                        },
                    ],
                },
                {
                    path: "/feedback",
                    name: "",
                    component: () => import("@/view/pages/feedback/Feedback"),
                    children: [
                        {
                            path: "/",
                            name: "feedback-list",
                            meta: {authorize: PERMISSION.XEM_DANH_SACH_PHONG_BAN_XU_LY},
                            component: () =>
                                import("@/view/pages/feedback/FeedbackIndex"),
                        },
                        {
                            path: "show/:id",
                            name: "show-feedback",
                            meta: {authorize: PERMISSION.XEM_DANH_SACH_PHONG_BAN_XU_LY},
                            component: () => import("@/view/pages/feedback/FeedbackShow")
                        },
                    ],
                },
                {
                    path: "/event",
                    name: "event",
                    component: () => import("@/view/pages/event/Event"),
                    children: [
                        {
                            name: "event-list",
                            path: "/",
                            meta: {authorize: PERMISSION.SU_KIEN},
                            component: () =>
                                import("@/view/pages/event/Event-List"),
                        },
                        {
                            name: "event-create",
                            path: "create",
                            meta: {authorize: PERMISSION.SU_KIEN},
                            component: () =>
                                import("@/view/pages/event/Event-Form"),
                        },
                        {
                            name: "event-edit",
                            path: "edit/:id",
                            meta: {authorize: PERMISSION.SU_KIEN},
                            component: () =>
                                import("@/view/pages/event/Event-Form"),
                        },
                    ],
                },
                {
                    path: "/workshop-club",
                    name: "workshop-club",
                    component: () => import("@/view/pages/workshop-club/Workshop-Club"),
                    children: [
                        {
                            name: "workshop-club-list",
                            path: "/",
                            meta: {authorize: PERMISSION.WORKSHOP_CLUB},
                            component: () =>
                                import("@/view/pages/workshop-club/Workshop-Club-List"),
                        },
                        {
                            name: "workshop-club-create",
                            path: "create",
                            meta: {authorize: PERMISSION.WORKSHOP_CLUB},
                            component: () =>
                                import("@/view/pages/workshop-club/Workshop-Club-Form"),
                        },
                        {
                            name: "workshop-club-edit",
                            path: "edit/:id",
                            meta: {authorize: PERMISSION.WORKSHOP_CLUB},
                            component: () =>
                                import("@/view/pages/workshop-club/Workshop-Club-Form"),
                        },
                    ],
                },
                {
                    path: "/off-lesson",
                    name: "off-lesson",
                    component: () => import("@/view/pages/offLesson/offLesson"),
                    children: [
                        {
                            name: "off-lesson-list",
                            path: "/",
                            meta: {authorize: PERMISSION.DUYET_NGHI_BUOI_HOC},
                            component: () =>
                                import("@/view/pages/offLesson/list"),
                        },
                    ],
                },
                {
                    path: "/q-a",
                    name: "q-a",
                    component: () => import("@/view/pages/QA/QA"),
                    children: [
                        {
                            name: "q-a-list",
                            path: "/",
                            meta: {authorize: 'ALL'},
                            component: () =>
                                import("@/view/pages/QA/QA-List"),
                        },
                    ],
                },
                {
                    path: "/test-list",
                    name: "incomes",
                    component: () => import("@/view/pages/test/TestIncome"),
                    children: [
                        {
                            name: "incomes-list",
                            path: "/",
                            meta: {authorize: PERMISSION.TEST_DAU_VAO},
                            component: () =>
                                import("@/view/pages/test/TestIncomeIndex"),
                        },
                        {
                            name: "mark-point",
                            path: "mark-point/:id",
                            meta: {authorize: PERMISSION.TEST_DAU_VAO},
                            component: () =>
                                import("@/view/pages/test/TestMarkPoint"),
                        },
                    ],
                },
                {
                    path: "/course-transfers",
                    name: "course-transfers",
                    component: () => import("@/view/pages/course-transfer/CourseTransfer"),
                    children: [
                        {
                            name: "course-transfers-list",
                            path: "/",
                            meta: {authorize: PERMISSION.QUAN_LY_CHUYEN_NHUONG},
                            component: () =>
                                import("@/view/pages/course-transfer/CourseTransferIndex"),
                        },
                        {
                            name: "course-transfers-create",
                            path: "create",
                            meta: {authorize: PERMISSION.QUAN_LY_CHUYEN_NHUONG},
                            component: () =>
                                import("@/view/pages/course-transfer/CourseTransferCreate"),
                        },
                        {
                            name: "course-transfers-select-student",
                            path: "create/select-student/:from_student/contract/:contract_id",
                            meta: {authorize: PERMISSION.QUAN_LY_CHUYEN_NHUONG},
                            component: () =>
                                import("@/view/pages/course-transfer/CourseTransferSelectStudent"),
                        },
                    ],
                },
                {
                    path: "/installment-information",
                    name: "installment-information",
                    component: () => import("@/view/pages/installment/Installment"),
                    children: [
                        {
                            name: "installment-information-list",
                            path: "index",
                            meta: {authorize: PERMISSION.DUYET_TRA_GOP},
                            component: () => import("@/view/pages/installment/InstallmentInformationIndex"),
                        },
                        {
                            name: "installment-information-detail",
                            path: "detail/:id",
                            meta: {authorize: PERMISSION.DUYET_TRA_GOP},
                            component: () => import("@/view/pages/installment/InstallmentInformationDetail"),
                        }
                    ]
                },
                {
                    path: "/schedule-course",
                    name: "schedule-course",
                    component: () => import("@/view/pages/schedule-course/scheduleCourse"),
                    children: [
                        {
                            name: "schedule-course-list",
                            path: "/",
                            meta: {authorize: PERMISSION.QUAN_LY_LO_TRINH},
                            component: () =>
                                import("@/view/pages/schedule-course/list"),
                        },
                        {
                            name: "schedule-course-slide",
                            path: "detail/:id/slides",
                            meta: {authorize: PERMISSION.QUAN_LY_LO_TRINH},
                            component: () =>
                                import("@/view/pages/schedule-course/schedule-slide"),
                        },
                        {
                            name: "schedule-course-detail",
                            path: "detail/:id",
                            meta: {authorize: PERMISSION.QUAN_LY_LO_TRINH},
                            component: () =>
                                import("@/view/pages/schedule-course/detail"),
                        },
                        {
                            name: "schedule-course-edit",
                            path: "edit/:id/",
                            meta: {authorize: PERMISSION.QUAN_LY_LO_TRINH},
                            component: () =>
                                import("@/view/pages/schedule-course/detail-edit"),
                        },
                        {
                            name: "curriculum",
                            path: "curriculum/:id/",
                            meta: {authorize: PERMISSION.QUAN_LY_LO_TRINH},
                            component: () =>
                                import("@/view/pages/schedule-course/curriculum"),
                        }
                    ]
                },
                {
                    path: "/tester-list",
                    name: "testers",
                    component: () => import("@/view/pages/test/tester/Tester"),
                    children: [
                        {
                            name: "tester-list",
                            path: "/",
                            meta: {authorize: PERMISSION.NHAN_SU_ADMIN},
                            component: () =>
                                import("@/view/pages/test/tester/TesterIndex"),
                        },
                    ],
                },
                {
                    path: "/exam",
                    name: "exam",
                    component: () => import("@/view/pages/exam/Exam"),
                    children: [
                        {
                            name: "exam-index",
                            path: "/",
                            meta: {authorize: PERMISSION.BUOI_KIEM_TRA},
                            component: () =>
                                import("@/view/pages/exam/ExamIndex"),
                        },
                        {
                            path: "detail/:id",
                            name : "exam-detail",
                            meta: {authorize: PERMISSION.CHI_TIET_BUOI_KIEM_TRA},
                            component: () => import("@/view/pages/exam/ExamDetail")
                        },
                        {
                            name: "mark-by-class",
                            path: "mark-by-class/:class_id/:list_test_id",
                            meta: {authorize: "ALL"},
                            component: () =>
                                import("@/view/pages/exam/MarkByClass"),
                        },
                    ],
                },
                {
                    path: "/report-error",
                    name: "report-error",
                    component: () => import("@/view/pages/report-error/ErrorList"),
                    children: [
                        {
                            name: "report-error-index",
                            path: "/",
                            meta: {authorize: PERMISSION.BANG_BAO_LOI},
                            component: () =>
                                import("@/view/pages/report-error/ErrorList"),
                        },
                    ],
                },
                {
                    path: "/database-cluster",
                    name: "database-cluster",
                    meta: {authorize: PERMISSION.DATABASE_CLUSTER},
                    component: () => import("@/view/pages/setting/DatabaseCluster"),
                },
                {

                    path: "/tournament",
                    name: "tournament",
                    component: () => import("@/view/pages/Tournaments/Tournaments"),
                    children: [
                        {
                            path: "/",
                            name: "tournamentList",
                            meta: {authorize: PERMISSION.GIAI_DAU},
                            component: () => import("@/view/pages/Tournaments/TournamentList")
                        },
                        {
                            path: "tournament-detail/:id",
                            name: "tournamentDetail",
                            meta: {authorize: PERMISSION.GIAI_DAU},
                            component: () => import("@/view/pages/Tournaments/TournamentDetails")
                        },
                        {
                            path: "add-tournament",
                            name: "addTournament",
                            meta: {authorize: PERMISSION.GIAI_DAU},
                            component: () => import("@/view/pages/Tournaments/AddNewTournament")
                        },
                        {
                            path: "organize-tournament/:id",
                            name: "organizeTournament",
                            meta: {authorize: PERMISSION.GIAI_DAU},
                            component: () => import("@/view/pages/Tournaments/OrganizeTournament")
                        },
                    ]
                },
                {
                    path: "/infrastructure",
                    name: "infrastructure",
                    component: () => import("@/view/pages/infrastructure/infrastructure"),
                    children: [
                        {
                            path: "/",
                            name: "infrastructureList",
                            meta: {authorize: PERMISSION.XU_LY_YEU_CAU},
                            component: () => import("@/view/pages/infrastructure/InfrastructureList")
                        },
                        {
                            path: "detail/:id",
                            name: "infrastructureDetail",
                            meta: {authorize: PERMISSION.XU_LY_YEU_CAU},
                            component: () => import("@/view/pages/infrastructure/InfrastructureDetail")
                        },
                    ]
                },
                {
                    path: "crm/ticket",
                    name: "crm-ticket",
                    component: () => import("@/view/pages/crm/tickets/CrmTicket"),
                    children: [
                        {
                            name: "crm-ticket-index",
                            path: "/",
                            meta: {authorize: PERMISSION.XEM_DANH_SACH_HO_TRO},
                            component: () =>
                                import("@/view/pages/crm/tickets/CrmTicketIndex"),
                        },
                        {
                            name: "crm-ticket-create",
                            path: "create",
                            meta: {authorize: PERMISSION.THEM_MOI_HO_TRO},
                            component: () =>
                                import("@/view/pages/crm/tickets/CrmTicketCreate"),
                        },
                        {
                            name: "crm-ticket-edit",
                            path: "edit/:id",
                            meta: {authorize: PERMISSION.SUA_HO_TRO},
                            component: () =>
                                import("@/view/pages/crm/tickets/CrmTicketEdit"),
                        },
                        {
                            name: "crm-ticket-show",
                            path: "show/:id",
                            meta: {authorize: PERMISSION.XEM_CHI_TIET_HO_TRO},
                            component: () =>
                                import("@/view/pages/crm/tickets/CrmTicketShow"),
                        }
                    ],
                },
                {
                    path: "/tickets",
                    name: "ticket",
                    component: () => import("@/view/pages/tickets/Ticket"),
                    children: [
                        {
                            name: "ticket-index",
                            path: "/",
                            meta: {authorize: PERMISSION.XEM_DANH_SACH_TICKET},
                            component: () =>
                                import("@/view/pages/tickets/TicketIndex"),
                        },
                        {
                            name: "ticket-show",
                            path: "show/:id/",
                            meta: {authorize: PERMISSION.XEM_CHI_TIET_TICKET},
                            component: () =>
                                import("@/view/pages/tickets/TicketShow"),
                        },
                        {
                            name: "history-call-stringee",
                            path: "history-call-stringee",
                            meta: {authorize: PERMISSION.LICH_SU_CUOC_GOI},
                            // meta: {authorize: PERMISSION.XEM_CHI_TIET_TICKET},
                            component: () =>
                                import("@/view/pages/tickets/HistoryCallStringee"),
                        },
                        {
                            name: "call-statistics",
                            path: "call-statistics",
                            meta: {authorize: PERMISSION.BAO_CAO_CUOC_GOI},
                            // meta: {authorize: PERMISSION.XEM_CHI_TIET_TICKET},
                            component: () =>
                                import("@/view/pages/tickets/CallStatistics"),
                        }
                    ],
                },
                {
                    path: "/manage-school-schedules",
                    name: "manage-school-schedules",
                    component: () => import("@/view/pages/department/Department.vue"),
                    children: [
                        {
                            path: "/",
                            name: "school-schedules",
                            meta: {authorize: PERMISSION.LICH_HOC},
                            component: () => import("@/view/pages/school-schedules/ManageSchoolSchedules.vue"),
                        },
                    ],
                },
                {
                    path: "/report-error",
                    name: "report-error",
                    component: () => import("@/view/pages/report-error/ErrorList"),
                    children: [
                        {
                            name: "report-error-index",
                            path: "/",
                            meta: {authorize: PERMISSION.BANG_BAO_LOI},
                            component: () =>
                                import("@/view/pages/report-error/ErrorList"),
                        },
                    ],
                },
                {
                    path: "/report-error-response-handling",
                    name: "report-error-response-handling",
                    component: () => import("@/view/pages/report-error-response-handling/ErrorList"),
                    children: [
                        {
                            name: "report-error-response-handling-index",
                            path: "/",
                            meta: {authorize: PERMISSION.BANG_BAO_LOI_XU_LY_PHAN_HOI},
                            component: () =>
                                import("@/view/pages/report-error-response-handling/ErrorList"),
                        },
                    ],
                },
                {
                    path: "/category",
                    name: "category",
                    component: () => import("@/view/pages/category/Category"),
                    children: [
                        {
                            name: "category-list",
                            path: "/",
                            meta: {authorize: PERMISSION.QUAN_LY_DANH_MUC},
                            component: () =>
                                import("@/view/pages/category/Category-List"),
                        },
                        {
                            name: "category-create",
                            path: "create",
                            meta: {authorize: PERMISSION.QUAN_LY_DANH_MUC},
                            component: () =>
                                import("@/view/pages/category/Category-Form"),
                        },
                        {
                            name: "category-edit",
                            path: "edit/:id",
                            meta: {authorize: PERMISSION.QUAN_LY_DANH_MUC},
                            component: () =>
                                import("@/view/pages/category/Category-Form"),
                        },
                    ],
                },
                {
                    path: "/lead",
                    name: "lead-list",
                    component: () => import("@/view/pages/lead-website/LeadWebsite"),
                    children: [
                        {
                            name: "lead-index",
                            path: "/",
                            meta: {authorize: PERMISSION.QUAN_LY_LEAD},
                            component: () =>
                                import("@/view/pages/lead-website/LeadWebsite"),
                        },

                    ],
                },
                {
                    path: "/setting-page",
                    component: () => import("./view/pages/setting-page/setting-page-layout"),
                    children: [
                        {
                            path: "home",
                            name: "setting-home-page",
                            meta: {authorize: true},
                            component: () =>
                                import("./view/pages/setting-page/home-page"),
                        },
                        {
                            path: "ecosystem",
                            name: "setting-ecosystem-page",
                            meta: {authorize: true},
                            component: () =>
                                import("./view/pages/setting-page/ecosystem-page"),
                        },
                        {
                            path: "people-edutalk",
                            name: "setting-peopleEdutalk-page",
                            meta: {authorize: true},
                            component: () =>
                                import("./view/pages/setting-page/peopleInEdutalk-page"),
                        }
                    ],
                },
                {
                    path: "/event-company",
                    component: () => import("@/view/pages/event-company/EventLayout"),
                    children: [
                        {
                            path: "list",
                            name: "company-event-list",
                            meta: {authorize: PERMISSION.SU_KIEN_NOI_BO},
                            component: () =>
                                import("@/view/pages/event-company/EventCompanyList"),
                        },
                        {
                            path: "create",
                            name: "company-event-create",
                            meta: {authorize: PERMISSION.SU_KIEN_NOI_BO},
                            component: () =>
                                import("@/view/pages/event-company/EventCompanyForm"),
                        },
                        {
                            path: "edit/:id",
                            name: "company-event-edit",
                            meta: {authorize: PERMISSION.SU_KIEN_NOI_BO},
                            component: () =>
                                import("@/view/pages/event-company/EventCompanyForm"),
                        },
                        {
                            path: "subscribers/:id",
                            name: "company-event-subscribers",
                            meta: {authorize: PERMISSION.SU_KIEN_NOI_BO},
                            component: () =>
                                import("@/view/pages/event-company/EventCompanyUserList"),
                        }
                    ],
                },
                {
                    path: "/attendance",
                    name: "attendance-index",
                    component: () => import("@/view/pages/attendances/Attendance"),
                    children: [
                        {
                            name: "attendance-index",
                            path: "/",
                            meta: {authorize: PERMISSION.NHAN_SU_CHAM_CONG},
                            component: () =>
                                import("@/view/pages/attendances/AttendanceIndex"),
                        },
                    ],
                },
                {
                    path: "/test-input",
                    name: "test-input",
                    component: () => import("@/view/pages/test-input/TestInput"),
                    children: [
                        {
                            name: "calendar-index",
                            path: "calendar-index",
                            meta: {authorize: "ALL"},
                            component: () =>
                                import("@/view/pages/test-input/CalendarIndex"),
                        },
                        {
                            name: "task-manager",
                            path: "task-manager",
                            meta: {authorize: PERMISSION.QUAN_LY_TAC_VU},
                            component: () =>
                                import("@/view/pages/test-input/TaskManagerIndex"),
                        },
                        {
                            name: "task-details",
                            path: "task-details/:id",
                            meta: {authorize: PERMISSION.TAC_VU_ADMIN_CA_NHAN},
                            component: () =>
                                import("@/view/pages/test-input/TaskDetails"),
                            beforeEnter: (to, from, next) => {
                                to.path
                                next();
                            }
                        },
                        {
                            name: "task-details-coordinator",
                            path: "task-details-coordinator/:id",
                            meta: {authorize: PERMISSION.QUAN_LY_TAC_VU},
                            component: () =>
                                import("@/view/pages/test-input/TestDetailCoordinator.vue"),
                            beforeEnter: (to, from, next) => {
                                to.path
                                next();
                            }
                        },
                        {
                            name: "report-test",
                            path: "report-test",
                            meta: {authorize: PERMISSION.REPORT_CA_TEST},
                            component: () =>
                                import("@/view/pages/test-input/ReportTest.vue"),
                            beforeEnter: (to, from, next) => {
                                to.path
                                next();
                            }
                        },
                        {
                            name: "mark-the-exam",
                            path: "mark-the-exam/:id",
                            meta: {authorize: "ALL"},
                            component: () =>
                                import("@/view/pages/test-input/MarkTheExam"),

                        },
                        {
                            name: "task-manager-calendar",
                            path: "task-manager-calendar",
                            meta: {authorize: PERMISSION.QUAN_LY_ADMIN},
                            component: () =>
                                import("@/view/pages/test-input/TaskManagerCalendarIndex"),
                        },
                        {
                            name: "task-manager-personal",
                            path: "task-manager-personal",
                            meta: {authorize: PERMISSION.TAC_VU_ADMIN_CA_NHAN},
                            component: () =>
                                import("@/view/pages/test-input/TaskManagerPersonalIndex"),
                        },
                        {
                            name: "entrance-test-schedule",
                            path: "entrance-test-schedule",
                            meta: {authorize: PERMISSION.LICH_TRUC_TEST_DAU_VAO},
                            component: () =>
                                import("@/view/pages/test-input/EntranceTestSchedule"),
                        }
                    ],
                },
                {
                    path: "/",
                    component: () => import("@/view/pages/task-management/TaskManagement.vue"),
                    children: [
                        {
                            path: "/task-management-list",
                            name: "TaskManagementList",
                            meta: {authorize: PERMISSION.XEM_DANH_SACH_TAC_VU_DIEU_PHOI_LOP_HOC || PERMISSION.QUAN_LY_TAC_VU_DIEU_PHOI_LOP_HOC},
                            component: () =>
                                import("@/view/pages/task-management/TaskManagementList.vue"),
                        },
                        {
                            name: "TaskArrangePersonal",
                            path: "/task-arrange-personal",
                            meta: {authorize: "ALL"},
                            component: () =>
                                import("@/view/pages/task-management/TaskArrangePersonal.vue"),
                        },
                        {
                            name: "TaskManagementDetail",
                            path: "/task-management-detail/:id",
                            meta: {authorize: "ALL"},
                            component: () =>
                                import("@/view/pages/task-management/TaskManagementDetail.vue"),
                        },
                    ],
                },

                {
                    path: "/tournament",
                    name: "tournament",
                    component: () => import("@/view/pages/Tournaments/Tournaments"),
                    children: [
                        {
                            path: "/",
                            name: "tournamentList",
                            meta: {authorize: PERMISSION.QUAN_LY_CHAM_CONG},
                            component: () => import("@/view/pages/Tournaments/TournamentList")
                        },
                        {
                            path: "tournament-detail",
                            name: "tournamentDetail",
                            meta: {authorize: PERMISSION.QUAN_LY_CHAM_CONG},
                            component: () => import("@/view/pages/Tournaments/TournamentDetails")
                        },
                        {
                            path: "add-tournament",
                            name: "addTournament",
                            meta: {authorize: PERMISSION.QUAN_LY_CHAM_CONG},
                            component: () => import("@/view/pages/Tournaments/AddNewTournament")
                        },
                        {
                            path: "organize-tournament",
                            name: "organizeTournament",
                            meta: {authorize: PERMISSION.QUAN_LY_CHAM_CONG},
                            component: () => import("@/view/pages/Tournaments/OrganizeTournament")
                        },
                    ]
                },
                {
                    path: "/school-link-source",
                    name: "SchoolLinkSource",
                    component: () => import("@/view/pages/school-link-source/SchoolLinkSource.vue"),
                    children: [
                        {
                            path: "/",
                            name: "listSchool",
                            meta: {authorize: PERMISSION.QUAN_LY_KENH_LK_TRUONG},
                            component: () => import("@/view/pages/school-link-source/ListSchool.vue")
                        },
                    ]
                },
                {
                    path: "/citime",
                    name: "citime",
                    component: () => import("@/view/pages/citime/Citime"),
                    children: [
                        {
                            name: "citime-index",
                            path: "/",
                            meta: {authorize: PERMISSION.THONG_TIN_CITIME},
                            component: () =>
                                import("@/view/pages/citime/CitimeIndex"),
                        },

                    ],
                },
                {
                    path: "/rewards-programs",
                    name: "rewards-programs",
                    component: () => import("@/view/pages/rewards-program/RewardsProgram"),
                    children: [
                        {
                            name: "list-program",
                            path: "/",
                            meta: {authorize: PERMISSION.CHUONG_TRINH_QUAY_THUONG},
                            component: () =>
                                import("@/view/pages/rewards-program/PrizeDrawIndex"),
                        },
                        {
                            name: "detail-program",
                            path: "detail-program/:id",
                            meta: {authorize: PERMISSION.CHUONG_TRINH_QUAY_THUONG},
                            component: () =>
                                import("@/view/pages/rewards-program/detail-program/DetailProgram"),
                        },

                    ]
                },
                {
                    path: "finance-branch-report",
                    name: "finance-branch-report",
                    component: () => import("@/view/pages/finance/FinancialReports/FinanceBranchReport"),
                    children: [
                        {
                            name: "finance-branch-report-index",
                            path: "/",
                            meta: {authorize: PERMISSION.BAO_CAO_TAI_CHINH_VP},
                            component: () =>
                                import("@/view/pages/finance/FinancialReports/FinanceBranchReportIndex"),
                        }
                    ],
                },
                {
                    path: "finance-shareholder-report",
                    name: "FinanceShareholderReport",
                    component: () => import("@/view/pages/finance/FinancialReports/FinanceShareholderReport.vue"),
                    children: [
                        {
                            name: "FinanceShareholderIndex",
                            path: "/",
                            meta: {authorize: PERMISSION.BAO_CAO_CO_DONG},
                            component: () =>
                                import("@/view/pages/finance/FinancialReports/FinanceShareholderIndex.vue"),
                        }
                    ],
                },
                {
                    path: "super-dash",
                    name: "super-dash",
                    component: () => import("@/view/pages/SuperDash/SuperDash.vue"),
                    children: [
                        {
                            name: "SuperDashIndex",
                            path: "list",
                            meta: {authorize: "ALL"},
                            component: () =>
                                import("@/view/pages/SuperDash/SuperDashIndex.vue"),
                        }
                    ],
                },
                {
                    path: "kpi-van-phong",
                    name: "kpi-van-phong",
                    component: () => import("@/view/pages/kpi-office/KpiOffice"),
                    children: [
                        {
                            name: "list-kpi-van-phong",
                            path: "/",
                            meta: {authorize: PERMISSION.TRAI_PHIEU},
                            component: () =>
                                import("@/view/pages/kpi-office/KpiOfficeIndex"),
                        },

                    ],
                },
            ],
        },

        {
            path: "/custom-error",
            name: "error",
            component: () => import("@/view/pages/error/Error.vue"),
            children: [
                {
                    path: "error-1",
                    name: "error-1",
                    component: () => import("@/view/pages/error/Error-1.vue"),
                },
                {
                    path: "error-2",
                    name: "error-2",
                    component: () => import("@/view/pages/error/Error-2.vue"),
                },
                {
                    path: "error-3",
                    name: "error-3",
                    component: () => import("@/view/pages/error/Error-3.vue"),
                },
                {
                    path: "error-4",
                    name: "error-4",
                    component: () => import("@/view/pages/error/Error-4.vue"),
                },
                {
                    path: "error-5",
                    name: "error-5",
                    component: () => import("@/view/pages/error/Error-5.vue"),
                },
                {
                    path: "error-6",
                    name: "error-6",
                    component: () => import("@/view/pages/error/Error-6.vue"),
                },
            ],
        },
        {
            path: "/",
            component: () => import("@/view/pages/auth/login_pages/Login-1"),
            children: [
                {
                    name: "login",
                    path: "/login",
                    component: () => import("@/view/pages/auth/login_pages/Login-1"),
                },
                {
                    name: "register",
                    path: "/register",
                    component: () => import("@/view/pages/auth/login_pages/Login-1"),
                },
            ],
        },
        {
            path: "/bill",
            component: () => import("@/view/pages/contracts/Bill"),
            name: "bill",
            meta: {authorize: PERMISSION.EDUTALK_THU_TIEN},
        },
        {
            path: "*",
            redirect: "/404",
        },
        {
            // the 404 route, when none of the above matches
            path: "/404",
            name: "404",
            component: () => import("@/view/pages/error/Error-1.vue"),
        },
        {
            // the 503 route, when none of the above matches
            path: "/maintain",
            name: "maintain",
            component: () => import("@/view/pages/error/Maintain.vue"),
        }
    ],
});
